import React, { useState } from 'react';
import axios from 'axios'
import '../BlogPageNewsLetter/BlogPageNewsLetter.scss';


const BlogNewsLetter = () => {

    const [successForm, setSuccessForm] = useState(false)

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
            setSuccessForm(true)
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/777c5d3d-1388-4aa2-9224-8e0a464d44ac",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    const sentButtonStyles = {
        marginTop: "10px",
        color: "white"
    }
    return (
        <div className="contact-form-container blog-news-letter-form">
            <div className="blogpage-newsletter-form sticky">
                <h3>Subscribe to our Newsletter</h3>
                <p>To stay updated on the latest trends and news in the Qatar property market, consider subscribing to our newsletter.</p>
                
                <form action="https://25spaces.us1.list-manage.com/subscribe/post?u=bd00ca1c85d639ee6ef230c32&amp;id=9e3170d85d" method="post" className='mc-embedded-subscribe-form' id="mc-embedded-subscribe-form News Letter" name="mc-embedded-subscribe-form" target="_blank">
                    <input type="hidden" name="formname" value="News Letter" />
                    <input className="half-input" type="text" name="NAME" id="mce-NAME" placeholder="Name" required />
                    <input className="half-input" type="email" name="EMAIL" id="mce-EMAIL" placeholder="Email Address" required />
                    <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="primary-btn subscribe-button" >Subscribe</button>
                </form>
              {/* <div className='terms'>
                  <span>By clicking subscribe, you agree to our <a href="">Terms & Conditions</a>  and <a href="">Privacy policy.</a></span>
                </div> */}

            </div>
        </div>
    )
}

export default BlogNewsLetter