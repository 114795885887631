import React from 'react'
import './all-services.scss'

import PageSubtitle from '../PageSubtitle/page-subtitle'
import ClearButton from '../buttons/ClearButton/clear-button.js'

import { StaticQuery, graphql, Link } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const AllServices = () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    services: allContentfulService(
                    sort: {fields: createdAt, order: ASC}) {
                        edges {
                            node {
                                id
                                slug
                                serviceTitle
                                serviceShortText
                                serviceMainText {
                                    raw
                                }
                                serviceMainImage{
                                ...on ContentfulAsset {
                                    contentful_id
                                    __typename
                                    gatsbyImageData(
                                        aspectRatio: 1.5
                                        cropFocus: CENTER
                                        placeholder: BLURRED
                                        width: 600
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="services-section">
                    {data.services.edges.map((service, i) => {
                        return (
                            <div key={i} className="services-container">
                                {/* <Link to={`/our-services/${service.node.slug}`} className='services-link'> */}
                                {/* <div className='service-thumbnail'>
                                        <GatsbyImage image={getImage(service.node.serviceMainImage)} alt={service.node.serviceMainImage.title} />
                                    </div> */}
                                <Link  className='title_link' to={`/our-services/${service.node.slug}/`}><h3> {service.node.serviceTitle}</h3></Link>
                                <p>{service.node.serviceShortText}</p>
                                <Link className='services-section__link' to={`/our-services/${service.node.slug}/`}>
                                    Read More
                                </Link>
                                {/* </Link> */}
                            </div>
                        )
                    })}
                </section>
            )}
        />
    )
}

export default AllServices