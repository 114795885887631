import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import './WhatsAppSticky.scss'

import { StaticQuery, graphql } from 'gatsby'

const WhatsAppSticky = () => {
    return (
        <StaticQuery
            query = {graphql`
                query {
                    whatsapp: allContentfulUrlAddresses (filter: {linkName: {eq: "whatsapp"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                }
            `}
            render = { data => (
                <div className="whatsapp-sticky-wrapper">
                    <FontAwesomeIcon className="font-awesome" icon={faWhatsapp}/>
                    <div className="whatsapp-sticky-container">
                        <a rel="noreferrer" aria-label="whatsapp" href={data.whatsapp.edges[0].node.linkUrlAddress} target="_blank">
                            <div className="whatsapp-sticky">
                                <p>WhatsApp <span>Click here to chat</span></p>
                            </div>
                        </a>
                    </div>
                </div>
            )}
        />
    )
}

export default WhatsAppSticky
