import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { Link } from "gatsby"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

import './ContentBlock.scss';



const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>
const Heading4 = ({ children }) => <h4>{children}</h4>
const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Text>{children}</Text>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <Heading3>{children}</Heading3>
      )
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <Heading4>{children}</Heading4>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
      )
    }
  },
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
}

const ContentBlock = (props) => {

  return (
    <section className='content-block-section'>


      {props.content &&
        <div className='content'>{renderRichText(props.content, options)}</div>
      }


    </section>
  )
}
export default ContentBlock;
