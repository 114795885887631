import React from 'react'
import './testimonial-block.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const displayStars = (stars) => {
    let starCount = []

    for (let i = stars; i<=5 && i>0; i--) {
        starCount.push (
            <FontAwesomeIcon className="font-awesome" icon={faStar}/>
        )
    }

    return starCount
}


const TestimonialBlock = (props) => {
    return (
        <div className="testimonial-container">
            <div className="rating-container">
                {displayStars(props.rating)}

            </div>
            <div className="testimonial-block">
            <p>{props.clientTestimonial}</p>
            <p><strong>{props.clientName}</strong></p>
            <p><em>{props.clientTitle}</em></p>
            </div>
        </div>
    )
}

export default TestimonialBlock