import React from 'react'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
// import IntroSideNav from '../components/IntroSideNav/intro-side-nav'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { graphql } from 'gatsby'
import SectionTitle from '../components/SectionTitle/section-title'
import AllNewsArticles from '../components/AllNewsArticles/all-news-articles'
import AllNewsPosts from '../components/AllNewsPosts/all-news-posts'
import ResidencyPermit from '../components/ResidencyPermit/ResidencyPermit'
import PageIntro from '../components/PageIntro/page-intro'
import ImageContainer from '../components/ImageContainer/image-container'


export const query = graphql`
    {
        inTheNews: allContentfulGeneralTitleIntroSection(
            filter: {title: {eq: "In The News"}}
            sort: {fields: createdAt, order: DESC}) {
            edges {
                node {
                id
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                    raw
                }
                pageHeaderImage {
                    gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: BOTTOM
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                      )
                      title
                }
                bannerButtonOneLink {
                    linkUrlAddress
                }
                bannerButtonOneText
                bannerButtonTwoLink {
                    linkUrlAddress
                }
                bannerButtonTwoText
                bannerHeading
                bannerText
                pageImage {
                    ...on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: CENTER
                        placeholder: BLURRED
                        width: 1000
                      )
                      title
                    }
                  }
                }
            }
        }
    }
`

const InTheNews = ({ data: {inTheNews}}) => {
    return (
        <Layout>

            <SeoHelmet
                metaTitle = "News on Qatar Real Estate Market | 25 Spaces Real Estate"
                metaDescription = "Stay informed and up-to-date with real estate market news from Qatar. Feel free to browse through 25 Spaces Real Estate's website. Call them for more info."
                ogimage = {inTheNews?.edges[0]?.node.pageHeaderImage}
            ></SeoHelmet>


            <GeneralPageSubHeader
                image={inTheNews.edges[0].node.pageHeaderImage}
                imageAlt={inTheNews.edges[0].node.pageHeaderImage.title}
                breadcrumb = "Home - "
                pageName = {inTheNews.edges[0].node.title}
                bannerHeading = {inTheNews.edges[0].node.bannerHeading}
                bannerText = {inTheNews.edges[0].node.bannerText}
                bannerButtonOneLink = {inTheNews.edges[0].node.bannerButtonOneLink}
                bannerButtonOneText = {inTheNews.edges[0].node.bannerButtonOneText}
                bannerButtonTwoLink = {inTheNews.edges[0].node.bannerButtonTwoLink}
                bannerButtonTwoText = {inTheNews.edges[0].node.bannerButtonTwoText}
            ></GeneralPageSubHeader>


            <section className="full-width-section">
                <div className="generic-page-content">
                    <SectionTitle
                        sectionNumber =""
                        sectionTitle = {inTheNews.edges[0].node.titleFirstBreak}
                        sectionTitleSpan = {inTheNews.edges[0].node.titleSecondBreak}
                    ></SectionTitle>
                    <PageIntro
                        introText = {inTheNews.edges[0].node.introText}
                    ></PageIntro>
                </div>

                <ImageContainer
                    image = {inTheNews.edges[0].node.pageImage}
                    imageAlt = {inTheNews.edges[0].node.pageImage.title}
                ></ImageContainer>

            </section> 
            {/* <AllNewsArticles></AllNewsArticles>  */}
            <AllNewsPosts></AllNewsPosts>

        </Layout>
    )
}

export default InTheNews