import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./PropertyCard.scss"

const PropertyCard = (props) => { 
  const { hit, propertydata, type, imageName, oneLiner, neighborhood, price, slug, paymentFrequency, isCommercial, isThisANewDevelopment, internationalCurrency, internationalPrice, provider } = props;
  const showPrice = (priceNumber) => {
    if (parseInt(priceNumber) > 0) {
      return `QAR ${priceNumber}`
    }
    return;
  }
  const priceNumber = new Intl.NumberFormat("en").format(hit?.propertyPrice ? hit?.propertyPrice : price)
  const internationalPriceNumber = internationalPrice ? new Intl.NumberFormat('en').format(internationalPrice) : 0;
  let Slug = hit?.slug ? hit?.slug : slug
  let propertyOnliner = hit?.propertyOneLiner ? hit?.propertyOneLiner : oneLiner
  let neighborhoods = hit?.neighborhood.title ? hit?.neighborhood?.title : neighborhood
  let propertyType = hit?.propertyType ? hit?.propertyType[0] : type[0];
  let providerType = hit?.provider ? hit?.provider : provider;
  var Image = null;
  var AltText = null;
  if (providerType === "myCrm") {
    Image = hit?.images ? hit?.images[0].url : propertydata.images[0].url;
  } else {
    if(hit?.propertyImages && hit?.propertyImages.length > 0){
      Image = getImage(hit?.propertyImages[0]);
      AltText = hit?.propertyImages[0].title ? hit?.propertyImages[0].title : null;
    }  else if(propertydata?.propertyImages && propertydata?.propertyImages.length > 0){
      Image = getImage(propertydata?.propertyImages[0]);
    }
  }
  var pagePath = `/property-for-sale/${Slug}/`
  if (propertyType === "Rent" || propertyType === "Commercial Rent") {
    pagePath = `/property-for-rent/${Slug}/`
  }
  if (isCommercial) {
    pagePath = `${Slug}`
  }
  if(propertyType === "Buy"){
    propertyType = "Sale"
  }

var altText = `${propertyOnliner.replace("RETAIL","RETAIL SPACE").toLowerCase()} for ${propertyType.replace("Commercial Rent","Rent").replace("Commercial Buy","Sale")} in ${neighborhoods}`
if(isCommercial){
  altText = propertyOnliner.replace("RETAIL SPACE WITH TERRACE","COMMERCIAL SPACE WITH TERRACE").replace("RETAIL","COMMERCIAL SPACE").toLowerCase()
}

//function to capitalize the first letter of each word
const words = altText.split(" ");

for (let i = 0; i < words.length; i++) {
  words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
}
var capitalizeAlt = words.join(" ");
let offPlan = false;
  if(hit?.isThisANewDevelopment || props.isThisANewDevelopment){
    offPlan = true;
  }
  if(hit?.isOffPlan || props?.isOffPlan){
    offPlan = true;
  }
  if(props?.neighborhood?.slug == "gewan-island-doha" || hit?.neighborhood?.slug == "gewan-island-doha"){
    //offPlan = true;
  }
  if(neighborhood == "Gewan Island, Doha"){
    //offPlan = true;
  }
  
  
  // console.log('PropertyCard', props, props?.neighborhood?.slug)

  return (
    <div className="property-card-section">
      <div className="property-wrapper">
        <Link to={`${pagePath}`}>
          {
            providerType === "myCrm" ?
              <img src={Image} alt= {capitalizeAlt} />
              :
              <GatsbyImage image={Image} alt={AltText} />
          }
         
        </Link>
        {offPlan &&
          <span className="tag">Off-Plan</span>
          }
      </div>
      <div className="about-properties">
        <div className="property-info">
          <div className="property-heading">
            {internationalPrice
              ? <p className="price-value font_bold_600">{internationalCurrency} {internationalPriceNumber}</p>
              : <p className="price-value font_bold_600">{showPrice(priceNumber)}{paymentFrequency && <span className='lowercase'> {paymentFrequency}</span>}</p>
            }
            <Link to={`${pagePath}`} className="btn">
              View Details +
            </Link>
          </div>
          {isCommercial ?
            <Link to={`${pagePath}`}><p className="prop-result-description">{propertyOnliner.replace("RETAIL SPACE WITH TERRACE","COMMERCIAL SPACE WITH TERRACE").replace("RETAIL","COMMERCIAL SPACE")}</p></Link>
          :
            <Link to={`${pagePath}`}><h5 className="prop-result-description">{propertyOnliner.replace("RETAIL","RETAIL SPACE")} for {propertyType.replace("Commercial Rent","Rent").replace("Commercial Buy","Sale")} in {neighborhoods}</h5></Link>
          } 
          {/* <Link to={`${pagePath}`}><p className="location font_bold_600">{neighborhoods}</p></Link> */}
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
