import React from 'react'
import logo from '../images/logo-color@2x.png'

import Layout from '../components/Layout/layout'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'


import { graphql } from 'gatsby'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import SectionTitle from '../components/SectionTitle/section-title'
import PageIntro from '../components/PageIntro/page-intro'
import ImageContainer from '../components/ImageContainer/image-container'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import AllServices from '../components/AllServices/all-services'

export const query = graphql`
    {
        services: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "Our Services"}}) {
            edges {
                node {
                    id
                    bannerHeading
                    bannerText
                    bannerButtonOneLink {
                      linkUrlAddress
                    }
                    bannerButtonOneText
                    bannerButtonTwoLink {
                      linkUrlAddress
                    }
                    bannerButtonTwoText
                    title
                    titleFirstBreak
                    titleSecondBreak
                    introText {
                        raw
                    }
                    pageHeaderImage {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: BOTTOM
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                          title
                    }
                    pageImage {
                        ...on ContentfulAsset {
                            contentful_id
                            __typename
                            gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            placeholder: BLURRED
                            width: 1000
                          )
                          title
                        }
                      }
                }
            }
        }
    }
`


const Services = ({ data: {services}}) => {

    const introText = services.edges[0].node.introText
    return (
        <Layout>

        <SeoHelmet
            metaTitle = "Real Estate Services in Qatar | 25 Spaces Real Estate"
            metaDescription = "Discover high-end residential and commercial real estate services, including top-notch property management solutions, offered by the expert agents at 25 Spaces Real Estate."
            ogimage = {services.edges[0].node.pageHeaderImage}
        ></SeoHelmet>

            <GeneralPageSubHeader
                image={services.edges[0].node.pageHeaderImage}
                imageAlt={services.edges[0].node.pageHeaderImage.title}
                breadcrumb = "Home - "
                pageName = {services.edges[0].node.title}
                bannerHeading = {services.edges[0].node.bannerHeading}
                bannerText = {services.edges[0].node.bannerText}
                bannerButtonOneLink = {services.edges[0].node.bannerButtonOneLink}
                bannerButtonOneText = {services.edges[0].node.bannerButtonOneText}
                bannerButtonTwoLink = {services.edges[0].node.bannerButtonTwoLink}
                bannerButtonTwoText = {services.edges[0].node.bannerButtonTwoText}
            ></GeneralPageSubHeader>

            <section className="full-width-section">
                <div className="generic-page-content">
                    <SectionTitle
                        sectionNumber =""
                        sectionTitle = {services.edges[0].node.titleFirstBreak}
                        sectionTitleSpan = {services.edges[0].node.titleSecondBreak}
                    ></SectionTitle>
                    { introText 
                    ? <PageIntro
                        introText = {services.edges[0].node.introText}
                    ></PageIntro>
                    : ""}


                    <AllServices></AllServices>
                    
                </div>
                <GenericPageImageContact
                    image = {services.edges[0].node.pageImage}
                    imageAlt = {services.edges[0].node.pageImage.title}
                    logo = {logo}
                ></GenericPageImageContact>
            </section>
        </Layout>
    )
}

export default Services



