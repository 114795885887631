import React from 'react'
import './clear-button.scss'

import { Link } from 'gatsby'

const ClearButton = (props) => {
    return (
        <div className="button-container">
            {/* <Link to={`/${props.href}`} className="clear-btn">
                {props.buttonText}
            </Link> */}
            <Link to={props.href} className="clear-btn" target={`${props.target ? "_blank" : ""}`}>
                {props.buttonText}
            </Link>
        </div>
    )
}

export default ClearButton 