import React, { useState } from 'react'

import PageSubtitle from '../PageSubtitle/page-subtitle'
import ClearButton from '../buttons/ClearButton/clear-button.js'
import { orderBy, cloneDeep } from "lodash"
import { StaticQuery, graphql, Link } from 'gatsby'
import ReadMore from '../../images/readmore.svg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const AllBlogPosts = ({ blogPosts }) => {
    let blogData = blogPosts.map((n) => {
        if (n.node.sorting == null) {
            n.node.sorting = 0
        }
        return n;
    });
    blogData = orderBy(blogData, ['sorting'], ['desc']);
    const blogPerRow = 5;
    const [next, setNext] = useState(blogPerRow);
    const handleMoreImage = () => {
        setNext(next + blogPerRow);
    };

    return (

        <section className="blog-posts-section" id="blog-section">
            {blogData.slice(0, next)?.map((blogPost, i) => {

                const d = new Date(blogPost?.node?.postDate)
                var date = d.toLocaleDateString("en-UK", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
                //console.log("date",date)
                return (
                    <div key={i} className="blog-block-container">
                        <div className="rightText-image-post-block">

                            <div className="blog-intro-text-container">
                                {/* <p className='blog-published-date'>{date}</p> */}
                                <Link to={`/blog/${blogPost.node.slug}/`} target="_blank"><h3>{blogPost.node.postTitle}</h3></Link>  
                            </div>
                            <div className="blog-image-container">
                                {/* {blogPost.node.category &&
                                    <p className='category'>{blogPost.node.category?.title}</p>
                                } */}

                                <Link to={`/blog/${blogPost.node.slug}/`} target="_blank"><GatsbyImage image={getImage(blogPost.node.postMainImage)} alt={blogPost.node.postMainImage.title} /></Link>
                            </div>
                        </div>
                        <div className="bottom-post-block ">
                            <p>{blogPost.node.postShortDescription.postShortDescription.length > 160 ?
                                `${blogPost.node.postShortDescription.postShortDescription.substring(0, 160)}...` : blogPost.node.postShortDescription.postShortDescription
                            }</p>
                            <ClearButton
                                buttonText="Read More +"
                                href={`/blog/${blogPost.node.slug}/`}
                                target="blank"
                            ></ClearButton>
                        </div>
                    </div>
                )
            })}
            {next < blogData?.length && (
                <div className="button-container">
                    <button onClick={handleMoreImage} className="clear-btn" >
                        Load More
                    </button>
                </div>
            )}
        </section>

    )
}

export default AllBlogPosts