import React from 'react'


import { graphql } from 'gatsby'
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import FullWidthTitle from '../components/FullWidthTitle/full-width-title'
import BlogPostRichText from '../components/BlogPostRichText/blog-post-rich-text'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import TeamMemberRichText from '../components/TeamMemberRichText/team-member-rich-text'
import PageTitle from '../components/PageTitle/PageTitle'

export const quert = graphql`
    query($slug: String!) {
        teamMember: contentfulTeamMember(slug: {eq: $slug}) {
            id
            fullName
            position
            slug
            description {
                raw
            }
            seo {
                metaTitle
                metaDescription {
                  metaDescription
                }
            }
            photo {
                ...on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                    aspectRatio: 0.9
                    cropFocus: CENTER
                    placeholder: BLURRED
                    width: 300
                    )
                }
            }
        }
    }
`


const TeamMemberPage = ({ data: { teamMember } }) => {

    return (
        <Layout>
            <SeoHelmet
                metaTitle={teamMember.seo.metaTitle}
                metaDescription={teamMember.seo.metaDescription.metaDescription}
            ></SeoHelmet>

            <GeneralPageSubHeader
                breadcrumb="Home - Our Team - "
                pageName={teamMember.fullName}
            ></GeneralPageSubHeader>

            <PageTitle
                className="page-title-section"
                title={teamMember.fullName}
                subtitle={teamMember.position}
            ></PageTitle>

            {/* <BlogPostRichText
                richText = {teamMember.description}
            ></BlogPostRichText> */}

            <TeamMemberRichText
                richText={teamMember.description}
                photo={teamMember.photo}
            ></TeamMemberRichText>

        </Layout>
    )
}

export default TeamMemberPage