import React from 'react'

import Layout from '../components/Layout/layout'


import { graphql } from 'gatsby'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import SectionTitle from '../components/SectionTitle/section-title'
import PageIntro from '../components/PageIntro/page-intro'
import ImageContainer from '../components/ImageContainer/image-container'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import AllTeamMembers from '../components/AllTeamMembers/all-team-members'

export const query = graphql`
    {
        team: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "Our Team"}}) {
            edges {
                node {
                    id
                    bannerHeading
                    bannerText
                    bannerButtonOneLink {
                      linkUrlAddress
                    }
                    bannerButtonOneText
                    bannerButtonTwoLink {
                      linkUrlAddress
                    }
                    bannerButtonTwoText
                    title
                    titleFirstBreak
                    titleSecondBreak
                    introText {
                        raw
                    }
                    pageHeaderImage {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: BOTTOM
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                          title
                    }
                    pageImage {
                        ...on ContentfulAsset {
                            contentful_id
                            __typename
                            gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            placeholder: BLURRED
                            width: 1000
                          )
                          title
                        }
                      }
                }
            }
        }
    }
`


const Team = ({ data: {team}}) => {

    const introText = team.edges[0].node.introText
    return (
        <Layout>

        <SeoHelmet
            metaTitle = "Read About out Team | 25 Spaces Real Estate"
            metaDescription = ""
        ></SeoHelmet>

            <GeneralPageSubHeader
                image={team.edges[0].node.pageHeaderImage}
                imageAlt={team.edges[0].node.pageHeaderImage.title}
                breadcrumb = "Home - "
                pageName = {team.edges[0].node.title}
                bannerHeading = {team.edges[0].node.bannerHeading}
                bannerText = {team.edges[0].node.bannerText}
                bannerButtonOneLink = {team.edges[0].node.bannerButtonOneLink}
                bannerButtonOneText = {team.edges[0].node.bannerButtonOneText}
                bannerButtonTwoLink = {team.edges[0].node.bannerButtonTwoLink}
                bannerButtonTwoText = {team.edges[0].node.bannerButtonTwoText}
            ></GeneralPageSubHeader>

            <section className="full-width-section">
                <div className="generic-page-content">
                    <SectionTitle
                        sectionNumber =""
                        sectionTitle = {team.edges[0].node.titleFirstBreak}
                        sectionTitleSpan = {team.edges[0].node.titleSecondBreak}
                    ></SectionTitle>
                    { introText 
                    ? <PageIntro
                        introText = {team.edges[0].node.introText}
                    ></PageIntro>
                    : ""}
                    
                </div>

                {/* <ImageContainer
                    image = {team.edges[0].node.pageImage}
                    imageAlt = {team.edges[0].node.pageImage.title}
                ></ImageContainer> */}

            </section>

            <AllTeamMembers></AllTeamMembers>
        </Layout>
    )
}

export default Team