import React from 'react'
import './slide-in-nav.scss'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { StaticQuery, graphql } from 'gatsby'
import FooterSocialMediaIcons from '../FooterSocialMediaIcons/footer-social-media-icons'

const SlideInNav = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    siteSettings: allContentfulSiteGeneralSettings(
                        filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
                    ) {
                        edges {
                        node {
                            crNumber
                            realEstateLicenseNumber
                        }
                        }
                    }
                    whatsapp: allContentfulUrlAddresses (filter: {linkName: {eq: "whatsapp"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                }
            `}
            render={data => (
                <div className={`slide-in-menu-main-container ${props.animate ? 'animate' : ''}`}>

                    <div onClick={props.handleAnimate} onKeyDown={props.handleAnimate} role="button" tabIndex="0" className="close-btn-container">
                        <div className="close-btn"></div>
                        <p>Close</p>
                    </div>

                    <div className="large-logo-container">
                        <Link to='/'><img
                            src={props.logo}
                            alt="25 Spaces real estate"
                            width={150}
                            height={60}
                        /></Link>
                    </div>

                    <div className="menu-container">
                        <div className="menu-list-container">
                            <Link to="/properties/" className="header-links">Properties</Link>
                            <ul>
                                <li>
                                    <Link to="/properties/">Our Listings</Link>
                                </li>
                                <li>
                                    <Link to="/commercial-properties/">Commercial Properties</Link>
                                </li>
                                <li>
                                    <Link to="/properties/in-The-Pearl-Island,-Doha/">The Pearl Properties</Link>
                                </li>
                                <li>
                                    <Link to="/properties/type-buy/in-gewan-island---doha/">Gewan Island Properties</Link>
                                </li>
                                <li>
                                    <Link to='/recent-sales/'>Recent Sales</Link>
                                </li>
                                <li>
                                    <Link to="/new-developments/">New Developments</Link>
                                </li>
                                <li>
                                    <Link to="/international-properties/">International Properties</Link>
                                </li>
                                
                                {/* <li>
                                    <Link to="/short-term-properties/">Short Stay</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to='/buy-with-us/' className="header-links">Buy</Link>
                            <ul>
                                <li>
                                        <Link to='/properties/type-buy/category-apartment/'>Apartments for Sale in Qatar</Link>
                                </li>
                                <li>
                                        <Link to='/properties/type-buy/in-the-pearl-island---doha/category-apartment/'>Apartments for Sale in The Pearl</Link>
                                </li>
                                <li>
                                        <Link to='/properties/type-buy/in-lusail-city---lusail/category-apartment/'>Apartments for Sale in Lusail</Link>
                                </li>
                                <li>
                                    <Link to='/buy-with-us/'>Buy with Us</Link>
                                </li>
                                <li>
                                    <Link to="/qatar-real-estate-investments/">Foreign Investors</Link>
                                </li>
                                <li>
                                    <Link to="/doha-neighborhoods-guide/">Doha Neighborhoods Guide</Link>
                                </li> 
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to='/properties/type-rent/' className="header-links">Rent</Link>
                            <ul>
                              <li>
                                  <Link to='/properties/type-rent/category-apartment/'>Apartments for Rent in Qatar</Link>
                             </li>
                              <li>
                                   <Link to="/properties/type-rent/in-the-pearl-island---doha/category-apartment/">Apartments for Rent in The Pearl</Link>
                             </li>
                             <li>
                                   <Link to="/properties/type-rent/in-lusail-city---lusail/category-apartment/">Apartments for Rent in Lusail</Link>
                            </li> 
                         </ul>
                        </div>

                        <div className="menu-list-container">
                            <Link to='/list-with-us/' className="header-links">Sellers</Link>
                            <ul>
                                <li>
                                    <Link to='/list-with-us/'>List with us</Link>
                                </li>
                                <li>
                                    <Link to='/marketing/'>Marketing</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to="/25-spaces-real-estate/" className="header-links">About Us</Link>
                            <ul>
                                <li>
                                    <Link to="/25-spaces-real-estate/">25 Spaces Real Estate</Link>
                                </li>
                                <li>
                                    <Link to="/our-office/">Our Office</Link>
                                </li>
                                <li>
                                    <Link to="/what-our-clients-are-saying/">What Our Clients Are Saying</Link>
                                </li> 
                                <li>
                                    <Link to="/careers/">Careers</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to="/our-services/" className="header-links">Our services</Link>
                            <ul>
                                <li>
                                    <Link to="/our-services/property-management/" >PROPERTY MANAGEMENT</Link>
                                </li>
                                <li>
                                    <Link to="/our-services/property-consultancy-advice/">PROPERTY CONSULTANCY ADVICE</Link>
                                </li>
                                <li>
                                    <Link to="/our-services/real-estate-valuation/">REAL ESTATE VALUATION</Link>
                                </li>
                                <li>
                                    <Link to="/our-services/professional-virtual-tours/" >PROFESSIONAL VIRTUAL TOURS</Link>
                                </li>
                                <li>
                                    <Link to="/our-services/professional-photography/" >PROFESSIONAL PHOTOGRAPHY</Link>
                                </li>
                                <li>
                                    <Link to="/our-services/snagging-and-handover/" >SNAGGING AND PRE-HANDOVER CHECKS</Link>
                                </li>
                                <li>
                                    <Link to="/our-services/real-estate-and-interior-design/">REAL ESTATE AND INTERIOR DESIGN</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to="/blogs/" className="header-links">Blogs</Link>
                        </div>
                        <div className="menu-list-container">
                            <Link to="/contact-us/" className="header-links">Contact Us</Link>
                        </div>
                    </div>

                    <div className="contact-info-container">
                        <div className="address-container">
                            <p>The Pearl - Qatar, Medina Centrale
                                <br />Al Hambra St. Building No. 8, Unit No. 64
                                <br />PO Box 14050
                                <br />Doha, Qatar</p>

                            <div className='footer-contact'>
                                <p>Call us:</p>
                                <a href="tel:+97444642525">+974 4464 2525</a>
                                <a href="tel:+97433118588">+974 3311 8588</a>
                            </div>

                            <div className='whatsapp-footer'>
                                <a rel="noreferrer" aria-label="whatsapp" href={data.whatsapp.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome--footer" icon={faWhatsapp} /><span>Chat with us</span></a>
                            </div>
                        </div>
                        <FooterSocialMediaIcons></FooterSocialMediaIcons>
                    </div>
                    <StaticImage className="slide-menu-bkg" src="../../images/slideMenu-bkg.jpg" alt="Menu background" />

                </div>
            )}
        />
    )
}

export default SlideInNav