import React from 'react';
import './features-round.scss'

import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FeaturesRound = ({title}) => {
    return (
        <StaticQuery
            query = { graphql`
                query {
                    round: allContentfulPageSubsection(filter: {slug: {eq: "lusail-city"}}) {
                        edges {
                        node {
                            id
                            otherElements {
                            ... on ContentfulCircleImage {
                                id
                                image {
                                    gatsbyImageData(
                                        aspectRatio: 1
                                        placeholder: BLURRED
                                        cropFocus: CENTER
                                    )
                                    title
                                }
                                title
                            }
                            }
                        }
                        }
                    }
                }
            `}
            render = {data => (
                <section class="round-images-features-section">
                    <div class="features-title-container">
                        <h3>{title}</h3>
                    </div>
                    <div class="features-blocks-container">
                        {data.round.edges[0].node.otherElements.map((round, i) => {
                            return (
                                <div key={i} class="feature-block">
                                    <div class="round-image-container">
                                        {/* <img src={round.image.file.url} alt={round.title} /> */}
                                        <GatsbyImage image={getImage(round.image)} alt={round.image.title} objectFit="cover" />
                                    </div>
                                    <h4>{round.title}</h4>
                                </div>
                            )
                        })}
                    </div>
                </section>
            )}
        />
    )
}

export default FeaturesRound