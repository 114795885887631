import React from 'react'
import './footer-social-media-icons.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { StaticQuery, graphql } from 'gatsby'


const FooterSocialMediaIcons = (props) => {
    return (
        <StaticQuery
            query = {graphql`
                query {
                    instagram: allContentfulUrlAddresses (filter: {linkName: {eq: "Instagram"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                    youtube: allContentfulUrlAddresses (filter: {linkName: {eq: "Youtube"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                    facebook: allContentfulUrlAddresses (filter: {linkName: {eq: "Facebook"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                    twitter: allContentfulUrlAddresses (filter: {linkName: {eq: "Twitter"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                    instagram: allContentfulUrlAddresses (filter: {linkName: {eq: "Instagram"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                    linkedin: allContentfulUrlAddresses (filter: {linkName: {eq: "LinkedIn"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                    whatsapp: allContentfulUrlAddresses (filter: {linkName: {eq: "whatsapp"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                }
            `}
            render = { data => (
                <div className={`${props.transparentBackground ? "transparent" : ""} footer-social-media-icons-container`}>
                    <div className="media-icon">
                        <a aria-label="instagram" rel="noreferrer" href={data.instagram.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome" icon={faInstagram}/></a>
                    </div>
                    <div className="media-icon">
                        <a aria-label="youtube" rel="noreferrer" href={data.youtube.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome" icon={faYoutube}/></a>
                    </div>
                    <div className="media-icon">
                        <a aria-label="facebook" rel="noreferrer" href={data.facebook.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome" icon={faFacebookF}/></a>
                    </div>
                    <div className="media-icon">
                        <a aria-label="twitter" rel="noreferrer" href={data.twitter.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome" icon={faTwitter}/></a>
                    </div>
                    <div className="media-icon">
                        <a aria-label="linkedin" rel="noreferrer" href={data.linkedin.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome" icon={faLinkedinIn}/></a>
                    </div>
                    {/* <div className="media-icon">
                        <a rel="noreferrer" aria-label="whatsapp" href={data.whatsapp.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome" icon={faWhatsapp}/></a>
                    </div> */}
                </div>
            )}
        />
    )
}

export default FooterSocialMediaIcons