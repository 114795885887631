import React from 'react'
import './transparent-button.scss';

import { Link } from 'gatsby'

const TransparentButton = (props) => {
    return (
        <>
        {props?.isPrimary
            ? <Link to={`/${props.href}`} className='primary-btn'>{props.buttonText}</Link> :
            props.isBlack ? <Link to={`/${props.href}`} className='black-btn'>{props.buttonText}</Link>
            : <Link to={`/${props.href}`} className='transparent-btn'>{props.buttonText}</Link>
        }
        </>
    )
}

export default TransparentButton