import React, { useState } from 'react'
import './popup-form.scss'

import axios from 'axios'

const PopupForm = (props) => {

  const [successForm, setSuccessForm] = useState(false)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
      setSuccessForm(true)
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/777c5d3d-1388-4aa2-9224-8e0a464d44ac",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  const sentButtonStyles = {
    marginTop: "30px",
  }


  return (
    <section className={`page-contact-form-section ${props.openForm ? "" : "hidden"}`}>

      <div className="page-contact-form-container">
        <div onClick={props.closeForm} onKeyDown={props.closeForm} role="button" tabIndex="0" className="close-btn-container-black">
          <div className="close-btn"></div>
          <p>Close</p>
        </div>
        <h4>{props.formTitle}</h4>
        <p>{props.formIntro}</p>
        <form accept-charset="UTF-8" onSubmit={handleOnSubmit} id={props.formTitle}>
          <input type="hidden" name="formname" value={props.formTitle} />
          <input className="half-input" type="text" name="first-name" placeholder="First Name" required />
          <input className="half-input" type="text" name="last-name" placeholder="Last Name" required />
          <input type="email" name="email" placeholder="Email" required />
          <input type="tel" name="phone" placeholder="Phone Number" required />
          <input type="text" name="message" placeholder="Your Message" required />
          <input className="set-input" type="text" value={`${props.formTitle} | ${props.formIntro}`} name="property" />

          {successForm
            ? <h4 style={sentButtonStyles}>Thank you!<br />Your Messages has been sent successfully.<br />We will get back to you soon.</h4>
            : ""
          }
          <button className="clear-btn" style={sentButtonStyles} type="submit">Send</button>
        </form>
      </div>
    </section>
  )
}

export default PopupForm