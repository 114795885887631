import React from 'react'
import './page-intro.scss'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>

    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text>{children}</Text>
            )
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return(
                <Heading3>{children}</Heading3>
            )
        },
        [INLINES.HYPERLINK]: (node, children) => {
            return (
                // <HyperLink>{children}</HyperLink>
                <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
            )
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
              // <img src={node.data.target.fixed.src} alt={node.data.target.title}/>
              <GatsbyImage image={getImage(node.data.target)} alt={node.data.target.title} />
            )
          }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
}

const PageIntro = (props) => {
    return (
        <section className="intro-section">
            <div className="intro-container">
                {props.introText && renderRichText(props.introText, options)}
            </div>
        </section>
    )
}

export default PageIntro