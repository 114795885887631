import React from 'react'
import './full-search-container.scss'
import SectionTitle from '../SectionTitle/section-title'

import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure, InfiniteHits, RefinementList, RangeInput, MenuSelect, Panel } from 'react-instantsearch-dom'
import ListingThumbnail from '../ListingThumbnail/listing-thumbnail';
import { StaticImage } from 'gatsby-plugin-image'

const searchClient = algoliasearch('YE99L4LOZ0', '17a4201cc18503a56f8870bceee610c8');




const FullSearchContainer = (props) => {
    return (
        <section className="full-search-container-section">
            <SectionTitle
                sectionNumber = {props.sectionNumber}
                sectionTitle = {props.sectionTitle}
                sectionTitleSpan = {props.sectionTitleSpan}
            ></SectionTitle>

            <InstantSearch searchClient={searchClient} indexName="25Spaces_properties">
                <Configure hitsPerPage={6} />
                <div className="searchbox-container">
                <h2 className="medium-h2 light">Search <strong>The 25 Spaces</strong> Listings</h2>
                    <Panel>
                        <div className="price-range-container">
                            <p>Price Range</p>
                            <RangeInput attribute="propertyPrice" />
                        </div>
                        <div className='select-options-container'>
                            <div className="select-menu-container">
                                <p>Number of Bedrooms</p>
                                <MenuSelect attribute="numberOfBedrooms" />
                            </div>
                            <div className="select-menu-container">
                                <p>Number of Bathrooms</p>
                                <MenuSelect attribute="numberOfBathrooms" />
                            </div>
                            <div className="select-menu-container">
                                <p>Neighborhood</p>
                                <MenuSelect attribute="neighborhood.title" />
                            </div>
                        </div>
                        <div className="checkbox-container">
                            <RefinementList attribute="propertyType" />
                        </div>
                    </Panel>
                    <StaticImage className="search-bkg" src="../../images/footer-bkg.jpg" alt="Footer" />

                </div>
                <div className="search-hits-container">
                    <InfiniteHits minHitsPerPage={6} hitComponent={ListingThumbnail} />
                </div>
            </InstantSearch>
            
        </section>
    )
}


export default FullSearchContainer