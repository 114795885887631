import React from 'react';
import './home-featured-listings.scss';

import BasicSection from '../BasicSection/basic-section';
import SectionTitle from '../SectionTitle/section-title';
import HomepageListingThumbnail from '../HomepageListingThumbnail/homepage-listing-thumbnail'
import PropertyCard from '../PropertyCard/PropertyCard';

import { StaticQuery, graphql } from 'gatsby'




const HomeFeaturedListings = () => {
    return <></>
    // return (
    //     <StaticQuery
    //         query={graphql`
    //             query {
    //                 properties: allContentfulHomepageElements (
    //                     filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}
    //                 ) {
    //                     edges {
    //                         node {
    //                             featuredListings {
    //                                propertyImages {
    //                                     gatsbyImageData(
    //                                     layout: FULL_WIDTH
    //                                     width: 600
    //                                     height: 500
    //                                     cropFocus: CENTER
    //                                     placeholder: BLURRED
    //                                     aspectRatio: 1
    //                                     )
    //                                     title
    //                                 }
    //                                 images{
    //                                     url
    //                                 }
    //                                 isThisANewDevelopment   
    //                                 isOffPlan
    //                                 provider
    //                                 propertyTitle
    //                                 propertyPrice
    //                                 propertyType
    //                                 propertyCategory
    //                                 propertyOneLiner
    //                                 neighborhood {
    //                                     title
    //                                     slug
    //                                 }
    //                                 slug
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         `}
    //         render={data => (

    //             <div className='featurelist-section'> 
    //                 <section className="featured-tabs">
    //                      <h2 className='featured-tabs__title'>Off-Plan Properties in JMJ Waterfront Residences</h2>
    //                     <div className='featured-tabs__wrapper'>
    //                         <ul className={`featured-tabs__properties  featured-tabs__properties--show }`}>
    //                             {data.properties.edges.map((property) =>
    //                                 property.node.featuredListings.map((featured, i) => {
    //                                     if( i < 3){
    //                                         return (
    //                                             <PropertyCard
    //                                                 key={i}
    //                                                 propertydata={featured}
    //                                                 price={featured.propertyPrice}
    //                                                 neighborhood={featured.neighborhood?.title}
    //                                                 slug={`${featured.slug}`}
    //                                                 imageName={(featured.images) ? '25 Space' : featured.propertyImages[0].title}
    //                                                 category={featured.propertyCategory}
    //                                                 oneLiner={featured.propertyOneLiner}
    //                                                 type={featured.propertyType}
    //                                                 isCommercial={false}
    //                                                 isOffPlan={featured.isOffPlan}
    //                                                 provider={featured.provider}
    //                                             />
    //                                         )
    //                                     }
                                      
    //                                 })
    //                             )}
    //                         </ul>
    //                     </div>

    //                 </section>
    //             </div>
    //         )}

    //     />
    // )
}

export default HomeFeaturedListings