import React from 'react'
import './image-container.scss'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
const ImageContainer = (props) => {
    return (
        <div className="image-container">
            <GatsbyImage image={getImage(props.image)} alt={props.imageAlt} />
        </div>
    )
}

export default ImageContainer