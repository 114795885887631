import React from 'react'
import './service-rich-text.scss'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// import { Link } from 'gatsby'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text>{children}</Text>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Heading3>{children}</Heading3>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage image={getImage(node.data.target)} alt={node.data.target.title} />
        )
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }

  const ServiceRichText = (props) => {
      return (
        <section className="service-section">
            {/* <div className='service-container service-photo'>
            <GatsbyImage image={getImage(props.photo)} alt={props.photo.title} />
            </div> */}
            {props.shortText
                ? <h4 className='service-shortext'>{props.shortText}</h4>
                : ""
            }
            <div className='service-container service-description'>
                {props.richText && renderRichText(props.richText, options)}
            </div>
        </section>
      )
  }

  export default ServiceRichText