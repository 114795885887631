import React, { useState } from 'react';
import Layout from '../components/Layout/layout';
import SeoHelmet from '../components/SeoHelmet/seo-helmet';
import SearchTemplate from '../templates/search-result-template'; 
import './properties.scss' 

class PropertyList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Layout>
        {/* <SeoHelmet
          metaTitle="Look for Apartment for Rent in Qatar | 25 Spaces Real Estate"
          metaDescription="Searching for an apartment for rent in Qatar? You've come to the right place. View our listings and tell us which property you'd like to see for a showing."
        ></SeoHelmet> */}

        <SearchTemplate path={`/properties/`} location={this.props.location} />
      </Layout>
    )
  }
}

export default PropertyList