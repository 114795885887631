import React from 'react'
import './generic-page-contact-box.scss'
import logo from '../../images/logo-color@2x.png'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { faPhone, faMobile } from '@fortawesome/free-solid-svg-icons'

const GenericPageContactBox = ( props ) => {

    const formatPhone = (phone) => {
        let phoneArray = String(phone).split(''); 
        let formattedNumber = `+${phoneArray[0]}${phoneArray[1]}${phoneArray[2]} ${phoneArray[3]}${phoneArray[4]}${phoneArray[5]}${phoneArray[6]} ${phoneArray[7]}${phoneArray[8]}${phoneArray[9]}${phoneArray[10]}`;
        return formattedNumber
    }
    return (


        <StaticQuery

            query = {graphql`
                query {
                    siteSettings: allContentfulSiteGeneralSettings(
                        filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
                    ) {
                        edges {
                        node {
                            companyPhoneNumber
                            companyCellPhoneNumber
                        }
                        }
                    }
                }
            `}

            
            render = { data => (
                <div className="contact-container">
                    <div className="logo-container">
                        <Link to="/"><img src={logo} alt="logo"/></Link>
                    </div>
                    <div className="icon-text-container multiple-lines">
                    <FontAwesomeIcon className="font-awesome" icon={faMapMarkerAlt}></FontAwesomeIcon>
                    <p>The Pearl - Qatar, Medina Centrale
                        <br/>Al Hambra St. Building No. 8, Unit No. 64
                        <br/>PO Box 14050, Doha, Qatar</p>
                    </div>
                    <div className="icon-text-container">
                      <p>Sunday to Thursday - 9 am to 6 pm
                      <br/>Friday - Closed
                      <br/>Saturday - 9 am to 5:30 pm</p>
                
                    </div>
                    <div className="icon-text-container">
                    <FontAwesomeIcon className="font-awesome" icon={faEnvelope}></FontAwesomeIcon>
                    <a href="mailto:info@25spaces.com">info@25spaces.com</a>
                    </div>
                    <div className="icon-text-container">
                        <div>
                            <FontAwesomeIcon className="font-awesome" icon={faPhone}></FontAwesomeIcon>
                            <a href={`tel: +${data.siteSettings.edges[0].node.companyPhoneNumber}`}>{formatPhone(data.siteSettings.edges[0].node.companyPhoneNumber)}</a> 
                        </div>
                        <div>
                            <FontAwesomeIcon className="font-awesome" icon={faMobile}></FontAwesomeIcon>
                            <a href={`tel: +${data.siteSettings.edges[0].node.companyCellPhoneNumber}`}>{formatPhone(data.siteSettings.edges[0].node.companyCellPhoneNumber)}</a>
                        </div>

                        
                    </div>



                    <a href="tel:+97433118588">+974 3311 8588</a>
                </div>
            )}
        />

        
    )
}

export default GenericPageContactBox