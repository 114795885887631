import React from 'react'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
// import IntroSideNav from '../components/IntroSideNav/intro-side-nav'
import ReactPlayer from 'react-player'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { graphql } from 'gatsby'
import SectionTitle from '../components/SectionTitle/section-title' 
import ResidencyPermit from '../components/ResidencyPermit/ResidencyPermit'
import PageIntro from '../components/PageIntro/page-intro' 
//import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'
import logo from '../images/logo-color@2x.png'


export const query = graphql`
    {
        inTheNews: allContentfulGeneralTitleIntroSection(
            filter: {title: {eq: "Qatar Real Estate Investments"}}
            sort: {fields: createdAt, order: DESC}) {
            edges {
                node {
                id
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                    raw
                    references {
                        ... on ContentfulAsset {
                            contentful_id
                            __typename
                            gatsbyImageData(
                                aspectRatio: 1.5
                                cropFocus: CENTER
                                layout: CONSTRAINED
                                placeholder: BLURRED
                              )
                              title
                            }
                        }
                }
                pageHeaderImage {
                    gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: BOTTOM
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                      )
                      title
                }
                bannerButtonOneLink {
                    linkUrlAddress
                }
                bannerButtonOneText
                bannerButtonTwoLink {
                    linkUrlAddress
                }
                bannerButtonTwoText
                bannerHeading
                bannerText
                displayGoogleReviews
                videoLink
                pageImage {
                    ...on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: CENTER
                        placeholder: BLURRED
                        width: 1000
                      )
                      title
                    }
                  }
                }
            }
        }
    }
`
const ForeignInvestors = ({ data: { inTheNews } }) => {
    var bannerButtonOneLink= "#residency-form"

    return (
        <Layout className="qatar-real-esate-investment">

            <SeoHelmet
                metaTitle="Qatar Real Estate Investments | 25 Spaces Real Estate"
                metaDescription="Looking to invest in Qatari real estate as a foreign investor? Contact 25 Spaces real estate agents for professional advice and guidance."
                ogimage = {inTheNews?.edges[0]?.node.pageHeaderImage}
            ></SeoHelmet>


            <GeneralPageSubHeader
                image={inTheNews.edges[0].node.pageHeaderImage}
                imageAlt={inTheNews.edges[0].node.pageHeaderImage.title}
                breadcrumb="Home - "
                pageName={inTheNews.edges[0].node.title}
                bannerHeading={inTheNews.edges[0].node.bannerHeading}
                bannerText={inTheNews.edges[0].node.bannerText}
                // bannerButtonOneLink={inTheNews.edges[0].node.bannerButtonOneLink}
                bannerButtonOneLink={{linkUrlAddress : bannerButtonOneLink}}
                bannerButtonOneText={inTheNews.edges[0].node.bannerButtonOneText}
                bannerButtonTwoLink={inTheNews.edges[0].node.bannerButtonTwoLink}
                bannerButtonTwoText={inTheNews.edges[0].node.bannerButtonTwoText}
                showGoogleReviews ={inTheNews.edges[0].node.displayGoogleReviews ? true : false}
            ></GeneralPageSubHeader>


            <section className="full-width-section top-section">
                <div className="generic-page-content">
                    <SectionTitle
                        sectionNumber=""
                        sectionTitle={inTheNews.edges[0].node.titleFirstBreak}
                        sectionTitleSpan={inTheNews.edges[0].node.titleSecondBreak}
                    ></SectionTitle>
                    {inTheNews.edges[0].node.videoLink &&
                        <div className='content-video'>
                        <ReactPlayer url={inTheNews.edges[0].node.videoLink} />
                        </div>
                    } 
                    <PageIntro
                        introText={inTheNews.edges[0].node.introText}
                    ></PageIntro>
                </div> 
                {/* <GenericPageImageContact
                    image={inTheNews.edges[0].node.pageImage}
                    imageAlt={inTheNews.edges[0].node.pageImage.title}
                    logo={logo}
                ></GenericPageImageContact> */}

            </section>
            <div>
                <ResidencyPermit></ResidencyPermit>
            </div>
        </Layout>
    )
}

export default ForeignInvestors