import React, { useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/logo-color@2x.png'
import Header from '../Header/header'
import SlideInNav from '../SlideInNav/slide-in-nav'
import SideSocialMediaIcons from '../SideSocialMediaIcons/side-social-media-icons'
import Footer from '../Footer/footer'
import './layout-homepage.scss'
//import LanguageSwitcher from '../LanguageSwitcher/language-switcher'

const LayoutHomepage = ({ children }) => {

    const [animate, setAnimate] = useState(false);
    //const [ lang, setLang ] = useState('');

    // useEffect(() => {
    //     setLang(document.documentElement.lang);
    // })

    const handleAnimate = () => {
        setAnimate(!animate);
    }

    return (
        <>
            {/* {lang === 'en' ? <LanguageSwitcher></LanguageSwitcher> : ''} */}
            <Header
                handleAnimate={handleAnimate}
                logo={logo}
                // homepageHeader="homepage"
                ></Header>
            <SlideInNav handleAnimate={handleAnimate} animate={animate} logo={logo}></SlideInNav>
            <main>{children}</main>
            <Footer logo={logo}></Footer>

        </>
    )
}

LayoutHomepage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LayoutHomepage