import React from 'react'
import BasicSection from '../components/BasicSection/basic-section'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
import SectionTitle from '../components/SectionTitle/section-title'
import TwoColumnsInnerSection from '../components/TwoColumnsInnerSection/two-columns-inner-section'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { graphql } from 'gatsby'
import TestimonialsSmall from '../components/TestiomonialsSmall/testimonials-small'
import PageSubsection from '../components/PageSubsection/page-sub-section'
import ImageContainer from '../components/ImageContainer/image-container.js'
import PageIntro from '../components/PageIntro/page-intro'
import PropertyCard from '../components/PropertyCard/PropertyCard';
export const query = graphql`
    {
        properties: allContentfulProperty(filter: {neighborhood: {slug: {eq: "the-pearl-qatar"}}} sort: {fields: createdAt, order: DESC}) {
            edges {
              node {
                createdAt
                propertyImages {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    width: 600
                    height: 500
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1
                    )
                    title
                }
                images{
                  url
                }
                provider
                propertyPrice
                propertyType
                paymentFrequency
                neighborhood {
                  title
                  slug
                }
                slug
                propertyOneLiner
              }
            }
        }
        pearlProperties: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "The Pearl Properties"}}) {
            edges {
              node {
                id
                bannerHeading
                bannerText
                bannerButtonOneLink {
                  linkUrlAddress
                }
                bannerButtonOneText
                bannerButtonTwoLink {
                  linkUrlAddress
                }
                bannerButtonTwoText
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                  raw
                }
                pageHeaderImage {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
                pageImage {
                  ...on ContentfulAsset {
                      contentful_id
                      __typename
                      gatsbyImageData(
                      aspectRatio: 1.5
                      cropFocus: CENTER
                      placeholder: BLURRED
                      width: 1000
                    )
                    title
                  }
                }
              }
            }
          }
          about: allContentfulPageSubsection(filter: {sectionTitle: {eq: "About Us"}}) {
            edges {
            node {
                id
                sectionImage {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 600
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1.5
                    )
                    title
                }
                sectionTitle
                sectionText {
                raw
                }
                slug
            }
            }
        }
        strategy: allContentfulPageSubsection(filter: {sectionTitle: {eq: "Our Strategy"}}) {
          edges {
          node {
              id
              sectionImage {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 600
                  cropFocus: CENTER
                  placeholder: BLURRED
                  aspectRatio: 1.5
                  )
                  title
              }
              sectionTitle
              sectionText {
              raw
              }
              slug
          }
          }
      }
    }
`


const PearlProperties = ({ data: { properties, pearlProperties, about, strategy } }) => {
  return (
    <Layout>

      <SeoHelmet
        // metaTitle = {`25 Spaces Real Estate - ${pearlProperties.edges[0].node.title}`}
        metaTitle="Properties for sale in The Pearl &amp Apartments for Rent in The Pearl | 25 Spaces Real Estate"
        metaDescription="Find properties for sale or appartments for Rent in The Pearl. We offer exclusive properties in The Pearl Island ranging from studios, penthouses and beachfront villas"
      ></SeoHelmet>
      <GeneralPageSubHeader
        image={pearlProperties.edges[0].node.pageHeaderImage}
        imageAlt={pearlProperties.edges[0].node.pageHeaderImage.title && pearlProperties.edges[0].node.pageHeaderImage.title}
        breadcrumb="Home - Properties - "
        pageName={pearlProperties.edges[0].node.title}
        bannerHeading={pearlProperties.edges[0].node.bannerHeading}
        bannerText={pearlProperties.edges[0].node.bannerText}
        bannerButtonOneLink={pearlProperties.edges[0].node.bannerButtonOneLink}
        bannerButtonOneText={pearlProperties.edges[0].node.bannerButtonOneText}
        bannerButtonTwoLink={pearlProperties.edges[0].node.bannerButtonTwoLink}
        bannerButtonTwoText={pearlProperties.edges[0].node.bannerButtonTwoText}
      ></GeneralPageSubHeader>
      <BasicSection>
        <SectionTitle
          sectionNumber=""
          sectionTitle={pearlProperties.edges[0].node.titleFirstBreak}
          sectionTitleSpan={pearlProperties.edges[0].node.titleSecondBreak}
        ></SectionTitle>
      </BasicSection>
      {properties.edges.length > 0
        ? <section className='basic-section property-section'>

          {properties.edges.map((property, i) => {
            return (
              <PropertyCard
                key={i}
                propertydata={property.node}
                price={property.node.propertyPrice}
                neighborhood={property.node.neighborhood?.title}
                slug={`${property.node.slug}`} 
                category={property.node.propertyCategory}
                oneLiner={property.node.propertyOneLiner}
                type={property.node.propertyType}
                paymentFrequency={property.node.paymentFrequency}
                isCommercial={false}
                provider={property.node.provider}
              />

            )
          })}
        </section>
        : ""
      }

      <PageSubsection
        subtitle={about.edges[0].node.sectionTitle}
        sectionText={about.edges[0].node.sectionText}
        image={about.edges[0].node.sectionImage}
        imageAlt={about.edges[0].node.sectionImage.title}
      ></PageSubsection>

      <PageSubsection
        subtitle={strategy.edges[0].node.sectionTitle}
        sectionText={strategy.edges[0].node.sectionText}
        image={strategy.edges[0].node.sectionImage}
        imageAlt={strategy.edges[0].node.sectionImage.title}
      ></PageSubsection>

      <TestimonialsSmall></TestimonialsSmall>
    </Layout>
  )
}

export default PearlProperties