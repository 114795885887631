import React, { useState } from 'react';
// import './our-listings.scss'

import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import {Helmet} from 'react-helmet'




import { graphql } from 'gatsby'
import FullSearchContainer from '../components/FullSearchContainer/full-search-container';

export const query = graphql`
        {
            siteSetting: allContentfulSiteGeneralSettings {
                edges {
                  node {
                    ourListingsHeaderImage {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: BOTTOM
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                          title
                    }
                  }
                }
              }
        }
`







const OurListings = ({ data: {siteSetting} }) => {





    return (
        <Layout>

            <SeoHelmet
                metaTitle = "Look for Apartment for Rent in Qatar | 25 Spaces Real Estate"
                metaDescription = "Searching for an apartment for rent in Qatar? You've come to the right place. View our listings and tell us which property you'd like to see for a showing."
            ></SeoHelmet>

            <GeneralPageSubHeader
                image={siteSetting.edges[0].node.ourListingsHeaderImage}
                imageAlt={siteSetting.edges[0].node.ourListingsHeaderImage.title}
                breadcrumb = "Home - Properties - "
                pageName = "Our Listings"
            ></GeneralPageSubHeader>

            <FullSearchContainer
                sectionNumber = ""
                sectionTitle = "Our"
                sectionTitleSpan = "Listings"
            ></FullSearchContainer>

        </Layout>
    )
}

export default OurListings