import React from 'react';
import './section-with-background.scss';
import sampleBkg from '../../images/temp/doha-boats1.webp';

const SectionWithBackground = ({children}) => {
    return (
        <section className="section-with-background">
            <img src={sampleBkg} alt='bakcground' />
            <div className='general-overlay-bakcground'></div>
            {children}
        </section>
    )
}

export default SectionWithBackground