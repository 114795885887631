import React from 'react';
import '../ServiceEndModule/ServiceEndModule.scss';
import TransparentButton from '../buttons/TransparentButton/transparent-button'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>
const Heading4 = ({ children }) => <h4>{children}</h4>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text>{children}</Text>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Heading3>{children}</Heading3>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return(
            <Heading4>{children}</Heading4>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
        )
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }


const ServiceEndModule = (props) =>{
    //console.log("props",props)
    return(
      props?.Title ?
        <div className='service-end-section'>
           <h2 className='end-title'>{props?.Title}</h2>
           {props.isBlogPage ? 
           <p className='end-desc'>{props?.description && renderRichText(props?.description, options)}</p> :
           <p className='end-desc'>{props?.description?.endCtaDescription}</p>
           }
           <div className='service-end-btn-section'>
                <TransparentButton
                    href={props?.href}
                    buttonText={props?.buttonText}
                    isPrimary
                ></TransparentButton>
            </div>
        </div>
        : ""
    )
}
export default ServiceEndModule;