import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import PopupForm from '../PopupForm/popup-form'
import WhatsApp from "../../images/WhatsApp-footer.svg"
import TelegramMobile from "../../images/telegramMobile.png"
import { useLocation } from "@reach/router"
import { StaticQuery, graphql } from 'gatsby'
import './FooterSticky.scss'

const FooterSticky = (props) => {
    const [openRequestForm, setOpenRequestForm] = useState(false)
    const [openViewingForm, setOpenViewingForm] = useState(false)
    const handleCloseRequestForm = () => {
        setOpenRequestForm(false)
    }
    const handleOpenRequestForm = () => {
        setOpenRequestForm(true)
      }
      const handleOpenViewingForm = () => {
        setOpenViewingForm(true)
      }
      const handleCloseViewingForm = () => {
        setOpenViewingForm(false)
      }

    let location = useLocation();
    var page = "other"
    if (location.pathname === "/") {
        page = "home"
    }
    return (
        <StaticQuery
            query={graphql`
            query {
                whatsapp: allContentfulUrlAddresses (filter: {linkName: {eq: "whatsapp"}}) {
                    edges {
                    node {
                        linkName
                        linkUrlAddress
                    }
                    }
                }
            }
        `}
            render={data => (
                <div className={`footer-sticky-wrapper ${page}`}>
                     {props.page !== "property-detail" &&
                        <Link className="primary-btn" to="/properties/">Find a Property</Link>
                     }
                    {props.page !== "property-detail" &&
                        <Link className="primary-btn black-btn" to="/contact-us/">Contact Us</Link>
                     }
                    {/* {props.page === "property-detail" &&
                        <button className="primary-btn" onClick={handleOpenRequestForm}>Request Details</button>
                    } */}
                    {/* {props.page !== "property-detail" &&
                    <a rel="noreferrer" aria-label="whatsapp" className="primary-btn whatsapp" href={data.whatsapp.edges[0].node.linkUrlAddress} target="_blank">
                        <img src={WhatsApp} alt={'whatsapp icon'} />Contact
                    </a>} */}
                    {props.page === "property-detail" &&
           <button className='btns primary-btn' onClick={handleOpenViewingForm}>Schedule a Viewing</button>
                    }
                    {/* <a rel="noreferrer" aria-label="telegram" className="primary-btn telegram" href={"https://telegram.me/+97450003725"} target="_blank">
                        <img src={TelegramMobile} alt={'telegram icon'} />Chat with us
                    </a>} */}

                    <a rel="noreferrer" aria-label="whatsapp" className="floating" href={data.whatsapp.edges[0].node.linkUrlAddress} target="_blank">
                        <img src={WhatsApp} alt={'whatsapp icon'} />
                    </a>
                    <PopupForm
                        closeForm={handleCloseViewingForm}
                        openForm={openViewingForm}
                        formTitle="Schedule a Viewing for"
                        formIntro={props.formIntro}
                    ></PopupForm>
                </div>
            )}
        />
    )
}

export default FooterSticky
