import React from 'react'
import BasicSection from '../components/BasicSection/basic-section'

import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
// import SearchBox from '../components/SearchBox/search-box'
import SectionTitle from '../components/SectionTitle/section-title'
import TwoColumnsInnerSection from '../components/TwoColumnsInnerSection/two-columns-inner-section'
import StaticThumbnail from '../components/StaticThumbnail/static-thumbnail'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import PropertyImageCard from '../components/PropertyCard/PropertyImageCard';

import { graphql } from 'gatsby'

export const query = graphql`
    {
        properties: allContentfulSoldProperty
        (sort: {order: DESC, fields: createdAt}) {
            edges {
              node {
                image {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    width: 1000
                  )
                  title
                }
                propertySoldDetail
              }
            }
          },
        siteSetting: allContentfulSiteGeneralSettings {
            edges {
              node {
                recentSalesHeaderImage {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
              }
            }
          }
    }
`

const RecentSales = ({ data: { properties, siteSetting } }) => {
  return (
    <Layout>

      <SeoHelmet
        metaTitle="Recent Sales in The Pearl-Qatar | 25 Spaces Real Estate"
        metaDescription="Planning to list your home in Qatar? Check out the recent apartments for sale in The Pearl-Qatar that 25 Spaces Real Estate sold for the best price possible."
        ogimage = {siteSetting?.edges[0]?.node.recentSalesHeaderImage}
      ></SeoHelmet>

      <GeneralPageSubHeader
        image={siteSetting.edges[0].node.recentSalesHeaderImage}
        imageAlt={siteSetting.edges[0].node.recentSalesHeaderImage.title}
        breadcrumb="Home - Properties - "
        pageName="Recent Sales"
      ></GeneralPageSubHeader>
      <BasicSection>
        <div className="searchbox-section">
          <SectionTitle
            sectionNumber=""
            sectionTitle="Recent"
            sectionTitleSpan="Sales"
          ></SectionTitle>
          {/* <SearchBox></SearchBox> */}
        </div>
      </BasicSection> 
      {properties.edges.length > 0
        ? <section className='basic-section property-section'>

          {properties.edges.map((property, i) => {
            return (
              <PropertyImageCard
                key={i}
                image={property.node.image}
                imageAlt={property.node.image.title}
                text={property.node.propertySoldDetail}
              /> 
            )
          })}
        </section>
        : ""
      } 
    </Layout>
  )
}

export default RecentSales