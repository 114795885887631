import React from 'react'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'
import Layout from '../components/Layout/layout'
import SectionTitle from '../components/SectionTitle/section-title'
import logo from '../images/logo-color@2x.png'
import sampleImage from '../images/temp/buy-with-us.jpg'

const BuyWithUs = (props) => {
    return (
        <Layout>
            <GeneralPageSubHeader
                breadcrumb = "Home - Buyers - "
                pageName = "Buy with Us"
            ></GeneralPageSubHeader>

            <section className = "full-width-section">
                <div className = "generic-page-content">
                    <SectionTitle
                        sectionNumber = ""
                        sectionTitle = "Buy"
                        sectionTitleSpan = "with us"
                    ></SectionTitle>
                    <div className="generic-page-text">
                        <p>Purchasing a property can be overwhelming, especially in a market you’re not familiar with. This is why choosing an honest and transparent real estate agency is key. Experience and guidance are important, and they make a significant difference in making sure you not only find the right property, but pay the best price for it while having an overall great purchasing experience.</p>
                        <p>At 25 Spaces Real Estate we pride ourselves for having access to a wide range of properties that are off-market, as well as properties that stand out from the competition. We are exceptionally honest with our clients, and only sell properties that we know for sure will have a positive impact on our client’s real estate portfolio. We take into consideration your needs and budget, find you the right property and make sure you make a well-informed decision. Please contact us to book an appointment.</p>
                    </div>
                    <h4 className="red-heading">Leave your Living Space To Us!</h4>
                </div>
                <GenericPageImageContact
                    image = {sampleImage}
                    logo = {logo}
                ></GenericPageImageContact>
            </section>

        </Layout>
    )
}

export default BuyWithUs