import React, { useState } from 'react';
import './featured-tabs.scss';

import { StaticQuery, graphql } from 'gatsby'
import PropertyCard from '../PropertyCard/PropertyCard';

const FeaturedTabs = () => {

    const [sale, setSale] = useState(true)
    const [rent, setRent] = useState(false)

    const clickSale = () => {
        setSale(true);
        setRent(false);
    }

    const clickRent = () => {
        setSale(false);
        setRent(true);
    }


    return (
        <StaticQuery
            query={graphql`
                query {
                    featured: allContentfulHomepageElements (
                        filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}
                    ) {
                        edges {
                          node {
                            homepageFeaturedListingHeading
                            homepageFeaturedListingDescription
                          }
                        }
                      }
                    rent: allContentfulProperty(
                        filter: {propertyType: {eq: "Rent"}, isThisANewDevelopment: {eq: false}, isThisAnInternationalProperty:{eq: false}}
                        limit: 3
                        sort: {order: DESC, fields: createdAt}
                        ) {
                        edges {
                            node {
                                propertyImages {
                                    gatsbyImageData(
                                    layout: FULL_WIDTH
                                    width: 600
                                    height: 500
                                    cropFocus: CENTER
                                    placeholder: BLURRED
                                    aspectRatio: 1
                                    )
                                    title
                                }
                                images{
                                    url
                                }
                                isThisANewDevelopment
                                provider
                                propertyTitle
                                propertyPrice
                                propertyType
                                propertyCategory
                                propertyOneLiner
                                neighborhood {
                                    title
                                    slug
                                }
                                slug
                            }
                        }
                    }

                    sale: allContentfulProperty(
                        filter: {propertyType: {eq: "Buy"}, isThisANewDevelopment: {eq: false}, isThisAnInternationalProperty:{eq: false}}
                        limit: 3
                        sort: {order: DESC, fields: createdAt}
                        ) {
                        edges {
                            node {
                                propertyImages {
                                    gatsbyImageData(
                                    layout: FULL_WIDTH
                                    width: 600
                                    height: 500
                                    cropFocus: CENTER
                                    placeholder: BLURRED
                                    aspectRatio: 1
                                    )
                                    title
                                }
                                images{
                                    url
                                }
                                isThisANewDevelopment
                                provider
                                propertyTitle
                                propertyPrice
                                propertyType
                                propertyCategory
                                propertyOneLiner
                                neighborhood {
                                    title
                                    slug
                                }
                                slug
                            }
                        }
                    }
                }
            `}

            render={data => (
                <section className='featured-tabs'>
                    <h2 className='featured-tabs__title'>{data.featured.edges[0].node.homepageFeaturedListingHeading}</h2>
                    <p className='featured-tabs__description'>{data.featured.edges[0].node.homepageFeaturedListingDescription}</p>
                    <ul className='featured-tabs__tabs'>
                        <li data-type="sale" className='featured-tabs__tab' onClick={() => clickSale()}><p className={`${sale ? "active" : ""}`}>Properties for Sale</p></li>
                        <li data-type="rent" className='featured-tabs__tab' onClick={() => clickRent()}><p className={`${rent ? "active" : ""}`}>Properties for Rent</p></li>
                    </ul>
                    <div className='featured-tabs__wrapper'>
                        <ul data-type="sale" className={`featured-tabs__properties ${sale ? "featured-tabs__properties--show" : ""}`}>
                            {data.sale.edges.map((featured, i) => {
                                return (
                                    <PropertyCard
                                        key={i}
                                        propertydata={featured.node}
                                        price={featured.node.propertyPrice}
                                        neighborhood={featured.node.neighborhood?.title}
                                        slug={`${featured.node.slug}`}
                                        imageName={(featured.node.images) ? '25 Space' : featured.node.propertyImages[0].title}
                                        category={featured.node.propertyCategory}
                                        oneLiner={featured.node.propertyOneLiner}
                                        type={featured.node.propertyType}
                                        isCommercial={false}
                                        isThisANewDevelopment={featured.node?.isThisANewDevelopment}
                                        provider={featured.node.provider}
                                    />
                                )
                            })}
                        </ul>
                        <ul data-type="rent" className={`featured-tabs__properties ${rent ? "featured-tabs__properties--show" : ""}`}>
                            {data.rent.edges.map((featured, i) => {
                                return (
                                    <PropertyCard
                                        key={i}
                                        propertydata={featured.node}
                                        price={featured.node.propertyPrice}
                                        neighborhood={featured.node.neighborhood.title}
                                        slug={`${featured.node.slug}`} 
                                        category={featured.node.propertyCategory}
                                        oneLiner={featured.node.propertyOneLiner}
                                        type={featured.node.propertyType}
                                        isCommercial={false}
                                        isThisANewDevelopment={featured.node?.isThisANewDevelopment}
                                        provider={featured.node.provider}
                                    />
                                )
                            })}
                        </ul>
                    </div>
                </section>
            )}
        />
    )
}

export default FeaturedTabs