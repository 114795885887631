import React, {useState, useCallback, useRef} from 'react'
import './listing-thumbnail.scss';
import { Link } from 'gatsby'

import { GatsbyImage, getImage} from 'gatsby-plugin-image'

const useHover = () => {
  const [ value, setValue ] = useState(false);

  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  const ref = useRef();

  const callbackRef = useCallback(
    node => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", handleMouseOver);
        ref.current.removeEventListener("mouseout", handleMouseOut);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("mouseover", handleMouseOver);
        ref.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut]
  );

  return [callbackRef, value];

}

const ListingThumbnail = ({ hit }) => {

  const [ hoverRef, isHovered] = useHover();

    return (
      <div className="listing-main"  ref={hoverRef}>
        <a href={`/our-listings/${hit.slug}`} className="listing-thumbnail-container" target='_blank'>
          <div className={`small-listing-thumbnail ${isHovered ? "animate" : ""}`}>
              <GatsbyImage image={getImage(hit.propertyImages[0])} alt={hit.propertyImages[0].title}/>
            </div>
          <ul className="intro-info">
            <li className='listing-price'><p>QAR {new Intl.NumberFormat('en').format(hit.propertyPrice)}</p></li>
            <li className="listing-city"><p>{hit.propertyOneLiner}</p></li>
          </ul>
          <ul className={`view-details ${isHovered ? "animate" : ""}`}>
            <li className="listing-city-large"><p>{hit.neighborhood?.title}</p></li>
            <li className="view-details-link">View Details +</li>
          </ul>
        </a>
      </div>
    )
}

export default ListingThumbnail




