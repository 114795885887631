import React from 'react'
import logo from '../images/logo-color@2x.png'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'

import { graphql } from 'gatsby'
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import FullWidthTitle from '../components/FullWidthTitle/full-width-title'
import BlogPostRichText from '../components/BlogPostRichText/blog-post-rich-text'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import TeamMemberRichText from '../components/TeamMemberRichText/team-member-rich-text'
import ServiceRichText from '../components/ServiceRichText/service-rich-text'
import PageTitle from '../components/PageTitle/PageTitle'
import BookAValuation from '../components/BookAValuation/book-a-valuation'
import ResidencyPermit from '../components/ResidencyPermit/ResidencyPermit'
import ServiceEndModule from '../components/ServiceEndModule/ServiceEndModule'

import '../templates/service-page.scss';


export const quert = graphql`
    query($slug: String!) {
        service: contentfulService(slug: {eq: $slug}) {
            id
            serviceTitle
            serviceBannerText {
                serviceBannerText
              }
            displayGoogleReviews
            endCtaTitle
            endCtaDescription {
                endCtaDescription
              }
            serviceShortText
            slug
            serviceMainText {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            layout: CONSTRAINED
                            width: 1000
                            placeholder: BLURRED
                          )
                          title
                        }
                    }
            }
            seo {
                metaTitle
                metaDescription {
                  metaDescription
                }
            }
            serviceMainImage {
                ...on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    layout: CONSTRAINED
                    width: 1200
                    placeholder: BLURRED
                    )
                }
            }
            pageHeaderImage {
                gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                  title
            }
        }
    }
`


const ServicePage = ({ data: { service } }) => {

    var bannerButtonOneText = '';
    var bannerButtonOneLink = '' ;
    if(service.slug === "real-estate-valuation" || service.slug === "property-management"){
        if(service.slug === "property-management"){
            bannerButtonOneLink = "#brochure-form" ;
            bannerButtonOneText = "Download the Brochure"
        }else{
            bannerButtonOneLink ="#real-estate-valuation-form";
            bannerButtonOneText = "Fill out the form"
        }
        
    }else{
        bannerButtonOneText = "Contact Us" ;
        bannerButtonOneLink =  `/contact-us/`;
    }

    return (
        <div className='services-details-section service-page-details'>
        <Layout className={service.slug}>
            <SeoHelmet
                metaTitle={service.seo.metaTitle}
                metaDescription={service.seo.metaDescription.metaDescription}
                ogimage = {service?.pageHeaderImage}
            ></SeoHelmet>

            <GeneralPageSubHeader
                image={service.pageHeaderImage}
                imageAlt={service.pageHeaderImage.title}
                breadcrumb="Home - Our Services - "
                pageName={service.serviceTitle}
                bannerHeading={service.serviceTitle} 
                bannerText={service?.serviceBannerText?.serviceBannerText}
                bannerButtonOneLink={{linkUrlAddress : bannerButtonOneLink}}
                bannerButtonOneText={bannerButtonOneText}
                showGoogleReviews ={service.displayGoogleReviews ? true : false}
                // bannerButtonOneLink = {bannerButtonOneLink}
                // bannerButtonOneText = {bannerButtonOneText}
               
            ></GeneralPageSubHeader>

            <section className="full-width-section">
                <div className="generic-page-content">
                    <PageTitle
                        title={service.serviceTitle}
                    ></PageTitle>


                    <ServiceRichText
                        shortText={service.serviceShortText}
                        richText={service.serviceMainText}
                    ></ServiceRichText>
                     <h2 className="small-h2 red-heading">Leave your Living Space To Us!</h2>

                     <div className='real-estate-form' id="real-estate-valuation-form"> 
                        {service.slug === "real-estate-valuation" &&
                                <BookAValuation />}
                    </div>

                </div>
                {/* <div className='generic-page-image-section-desktop'>
                    {service.slug === "real-estate-valuation" ?
                        <BookAValuation />

                        :
                        <GenericPageImageContact
                            image={service.serviceMainImage}
                            imageAlt={service.serviceMainImage.title}
                            logo={logo}
                        ></GenericPageImageContact>
                    }
                </div> */}
                
            </section>
            <section className='service-end-cta-section' id="brochure-form">
              { service.slug === "property-management" ? <ResidencyPermit /> : 
               <ServiceEndModule Title={service?.endCtaTitle} description={service?.endCtaDescription} href="contact-us" buttonText="Contact Us"/>}
               {/* <div className='generic-page-image-section-mobile'>
                {service.slug === "real-estate-valuation" ?
                        <BookAValuation />

                        :
                        <GenericPageImageContact
                            image={service.serviceMainImage}
                            imageAlt={service.serviceMainImage.title}
                            logo={logo}
                        ></GenericPageImageContact>
                    }
                </div> */}
            </section>
        </Layout>
        </div>
    )
}

export default ServicePage