import React from 'react';
import '../FeaturedTabs/featured-tabs.scss';
import { StaticQuery, graphql } from 'gatsby'
import PropertyCard from '../PropertyCard/PropertyCard';
import TransparentButton from '../buttons/TransparentButton/transparent-button';

const NewProjects = () => {
    return (
        <StaticQuery
            query={graphql`
                query { 
                    newprojects: allContentfulProperty(
                        filter: {neighborhood: {title: {eq: "Gewan Island, Doha"}}}
                        limit: 3
                        sort: {order: ASC, fields: propertyPrice}
                        ) {
                        edges {
                            node {
                                propertyImages {
                                    gatsbyImageData(
                                    layout: FULL_WIDTH
                                    width: 600
                                    height: 500
                                    cropFocus: CENTER
                                    placeholder: BLURRED
                                    aspectRatio: 1
                                    )
                                    title
                                }
                                images{
                                    url
                                }
                                isThisANewDevelopment   
                                provider
                                propertyTitle
                                propertyPrice
                                propertyType
                                propertyCategory
                                propertyOneLiner
                                neighborhood {
                                    title
                                    slug
                                }
                                slug
                            }
                        }
                    }
                }
            `}

            render={data => (
                data.newprojects &&
                <div className='gewan-new-projects-section'>
                    <section className='featured-tabs'>
                        <h2 className='featured-tabs__title'>New Projects in Gewan Island</h2>
                        <div className='featured-tabs__wrapper'>
                           <ul  className={`featured-tabs__properties  featured-tabs__properties--show }`}>
                           
                                {data.newprojects.edges.map((featured, i) => {
                                    //console.log("featured",featured)
                                    return (
                                        <PropertyCard
                                            key={i}
                                            propertydata={featured.node}
                                            price={featured.node.propertyPrice}
                                            neighborhood={featured.node.neighborhood?.title}
                                            slug={`${featured.node.slug}`}
                                            imageName={(featured.node.images) ? '25 Space' : featured.node.propertyImages[0].title}
                                            category={featured.node.propertyCategory}
                                            oneLiner={featured.node.propertyOneLiner}
                                            type={featured.node.propertyType}
                                            isCommercial={false}
                                            isThisANewDevelopment={featured.node?.isThisANewDevelopment}
                                            provider={featured.node.provider}
                                        />
                                    )
                                })}
                        
                            </ul>
                        </div>
                        <TransparentButton
                            href="properties/type-buy/in-gewan-island---doha/"
                            buttonText="Gewan Island Properties"
                            isBlack
                       ></TransparentButton>
                    </section>
                </div>
            )}
        />
    )
}

export default NewProjects