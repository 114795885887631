// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-25-spaces-real-estate-js": () => import("./../../../src/pages/25-spaces-real-estate.js" /* webpackChunkName: "component---src-pages-25-spaces-real-estate-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-commercial-properties-js": () => import("./../../../src/pages/commercial-properties.js" /* webpackChunkName: "component---src-pages-commercial-properties-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-doha-neighborhoods-guide-js": () => import("./../../../src/pages/doha-neighborhoods-guide.js" /* webpackChunkName: "component---src-pages-doha-neighborhoods-guide-js" */),
  "component---src-pages-in-the-news-js": () => import("./../../../src/pages/in-the-news.js" /* webpackChunkName: "component---src-pages-in-the-news-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-properties-js": () => import("./../../../src/pages/international-properties.js" /* webpackChunkName: "component---src-pages-international-properties-js" */),
  "component---src-pages-new-developments-js": () => import("./../../../src/pages/new-developments.js" /* webpackChunkName: "component---src-pages-new-developments-js" */),
  "component---src-pages-our-listings-old-js": () => import("./../../../src/pages/our-listings-old.js" /* webpackChunkName: "component---src-pages-our-listings-old-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-pearl-properties-js": () => import("./../../../src/pages/pearl-properties.js" /* webpackChunkName: "component---src-pages-pearl-properties-js" */),
  "component---src-pages-properties-js": () => import("./../../../src/pages/properties.js" /* webpackChunkName: "component---src-pages-properties-js" */),
  "component---src-pages-qatar-real-estate-investments-js": () => import("./../../../src/pages/qatar-real-estate-investments.js" /* webpackChunkName: "component---src-pages-qatar-real-estate-investments-js" */),
  "component---src-pages-recent-sales-js": () => import("./../../../src/pages/recent-sales.js" /* webpackChunkName: "component---src-pages-recent-sales-js" */),
  "component---src-pages-short-term-properties-old-js": () => import("./../../../src/pages/short-term-properties-old.js" /* webpackChunkName: "component---src-pages-short-term-properties-old-js" */),
  "component---src-pages-what-our-clients-are-saying-js": () => import("./../../../src/pages/what-our-clients-are-saying.js" /* webpackChunkName: "component---src-pages-what-our-clients-are-saying-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../../src/templates/blog-post-page.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-commercial-property-page-js": () => import("./../../../src/templates/commercial-property-page.js" /* webpackChunkName: "component---src-templates-commercial-property-page-js" */),
  "component---src-templates-general-page-js": () => import("./../../../src/templates/general-page.js" /* webpackChunkName: "component---src-templates-general-page-js" */),
  "component---src-templates-guide-page-js": () => import("./../../../src/templates/guide-page.js" /* webpackChunkName: "component---src-templates-guide-page-js" */),
  "component---src-templates-property-detail-page-js": () => import("./../../../src/templates/property-detail-page.js" /* webpackChunkName: "component---src-templates-property-detail-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-team-member-page-js": () => import("./../../../src/templates/team-member-page.js" /* webpackChunkName: "component---src-templates-team-member-page-js" */)
}

