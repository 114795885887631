import React from 'react'
import './clear-button-external.scss'

const ClearButton = (props) => {
    return (
        <div className="button-container">
            <a href={props.href} className="clear-btn" target={`${props.target ? "_blank" : ""}`}>
                {props.buttonText}
            </a>
        </div>
    )
}

export default ClearButton