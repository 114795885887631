import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/logo-color@2x.webp'
import Header from '../Header/header'
import SlideInNav from '../SlideInNav/slide-in-nav' 
import Footer from '../Footer/footer'
import './layout.scss'
//import LanguageSwitcher from '../LanguageSwitcher/language-switcher'


const Layout = ({ children, className }) => { 
    const [animate, setAnimate] = useState(false);
    //const [lang, setLang] = useState('');

    // useEffect(() => {
    //     setLang(document.documentElement.lang);
    // })
    const handleAnimate = () => {
        setAnimate(!animate);
    }

    return (
        <div className={className}>
            {/* {lang === 'en' ? <LanguageSwitcher></LanguageSwitcher> : ''} */}
            <Header handleAnimate={handleAnimate} logo={logo}></Header>
            <SlideInNav handleAnimate={handleAnimate} animate={animate} logo={logo}></SlideInNav>
            <main>{children}</main>
            <Footer logo={logo} page={className}></Footer>

        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout