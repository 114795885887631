import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import '../RelatedPosts/BlogCard.scss';

const BlogCard = (props) => {

  const { key,BlogImage,slug,BlogTitle ,BlogDate} = props;
  var Image = getImage(BlogImage)
  var blogPath = `/blog/${slug}/`
  return (
     <>
      <div className="blog-wrapper">
        <Link to={`${blogPath}`} >
          
              <GatsbyImage image={Image}  />
              <p className="blog-date">{BlogDate}</p>
              <p className="blog-card-title">{BlogTitle}</p>
            
        </Link>
      </div>
       
   </>
  )
}

export default BlogCard
