import React from 'react'
import { Link } from 'gatsby'
import './page-sub-section.scss'
import BasicSection from '../BasicSection/basic-section'
import PageSubtitle from '../PageSubtitle/page-subtitle'

import ImageContainer from '../ImageContainer/image-container'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Text>{children}</Text>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <Heading3>{children}</Heading3>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
      )
    }
  },
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
}





const PageSubsection = (props) => {
  return (
    <BasicSection>
      <PageSubtitle
        subtitle={props.subtitle}
        sectionId={props.sectionId}
      ></PageSubtitle>
      <div className={`section-content ${props.className}`}>
        <div className="content">
          {props.sectionText && renderRichText(props.sectionText, options)}
          {props?.btnLabel && props?.btnLink &&
            <Link className="primary-btn" to={props?.btnLink}>{props?.btnLabel}</Link>
          }

        </div>
        <ImageContainer
          image={props.image}
          imageAlt={props.imageAlt}
        ></ImageContainer>
      </div>
    </BasicSection>
  )
}

export default PageSubsection


// <TwoColumnsInnerSection>
// <div className={props.className}>
// <p>{props.sectionText && renderRichText(props.sectionText, options)}</p>
// <ImageContainer
//     image={props.image}
// ></ImageContainer>
// </div>
// </TwoColumnsInnerSection>

