 
import React from "react"
import "./PropertyCard.scss"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PropertyImageCard = (props) => {

  const { image, imageAlt, text } = props; 
  
  return (
    <div className="property-card-section image-card">
      <div className="property-wrapper">
        <GatsbyImage image={getImage(image)} alt={imageAlt} />
      </div>
      <div className="about-properties">
        <div className="property-info">
          <p className="prop-result-description">{text}</p> 
        </div>
      </div>
    </div>
  )
}

export default PropertyImageCard
