import React from "react"
import TelegramDesktop from "../../images/telegramdesktop.svg"
import { StaticQuery, graphql } from 'gatsby'
import '../TelegramSticky/TelegramSticky.scss'

const WhatsAppSticky = () => {
    return (
        <StaticQuery
            query = {graphql`
                query {
                    Telegram: allContentfulUrlAddresses (filter: {linkName: {eq: "Telegram"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                }
            `}
            render = { data => (
                <>
                <div className="telegram-sticky-wrapper">
                        <div className="telegram-sticky-container">
                    <a  
                        rel="noreferrer" 
                        aria-label="telegram"
                        href={data.Telegram.edges[0].node.linkUrlAddress} 
                        target="_blank"
                        className="floating-telegram"
                        >
                        <img
                            loading="lazy"
                            src={TelegramDesktop}
                            alt="telegram"
                            title="telegram"
                        />
                </a>
            </div> 
                        </div>
                
     </>
            )}
        />
    )
}

export default WhatsAppSticky
