// import React from 'react'
// import addToMailChimp from 'gatsby-plugin-mailchimp'

// export default class FooterSubscribe extends React.Component {

//     constructor() {
//         super()
//         this.state = {
//             email: "",
//             result: null
//         }
//     }
//     _handleSubmit = async e => {
//         e.preventDefault()
//         const result = await addToMailChimp(this.state.email)
//         this.setState({result: result})
//     }

//     handleChange = event => {
//         this.setState({ email: event.target.value })
//     }

//     render() {
//         return(
//             <div className="subscribe-form">
//                 <form onSubmit={this._handleSubmit}>
//                     <input
//                         type="email"
//                         name="email"
//                         placeholder="Email Address"
//                         onChange={this.handleChange}
//                     />
//                     <button type="submit">Subscribe</button>
//                 </form>
//             </div>
//         )
//     }
// }

import React from 'react'
import './footer-subscribe.scss'

const FooterSubscribe = () => {
    return (
        <>
            <div className="subscribe-form-container">
                <h2 className="small-h2">Subscribe to our newsletter</h2>
                <form action="https://25spaces.us1.list-manage.com/subscribe/post?u=bd00ca1c85d639ee6ef230c32&amp;id=9e3170d85d" method="post" id="mc-embedded-subscribe-form News Letter" name="mc-embedded-subscribe-form" target="_blank">
                    <input type="hidden" name="formname" value="News Letter" />
                    <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="email address" required />
                    <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="white-to-red-btn subscribe-button" >Subscribe</button>
                </form>
            </div>
        </>
    )
}

export default FooterSubscribe