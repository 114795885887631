import React, { useState } from 'react';
import axios from 'axios'
import PageSubtitle from '../PageSubtitle/page-subtitle'
import './ResidencyPermit.scss';
import {useLocation} from "@reach/router"


const ResidencyPermit = () => {

    const [successForm, setSuccessForm] = useState(false)

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
            setSuccessForm(true)
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/777c5d3d-1388-4aa2-9224-8e0a464d44ac",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    const sentButtonStyles = {
        marginTop: "30px",
    }
    var curpath = useLocation();
    var subtitle = '' ;
    //console.log("curpath",curpath);
    if(curpath.pathname === "/our-services/property-management/"){
        subtitle = "INTERESTED IN A PROPERTY MANAGEMENT SERVICE IN QATAR?"
    }else{
        subtitle = "How to get a Residency Permit in Qatar by Purchasing Property" 
    }
    return (

        <div className="contact-form-container ResidencyPermit all-articles-section" id="residency-form" >
            <PageSubtitle
                subtitle= {subtitle}
            ></PageSubtitle>
            <div className="book-a-viewing-from">
                {curpath.pathname === "/our-services/property-management/" ? <h4>Complete this form to receive our brochure</h4>
                 : 
                <h4>Complete this form to receive more information</h4> }
                <form accept-charset="UTF-8" onSubmit={handleOnSubmit} id="Residency Permit Form" >
                    <input type="hidden" name="formname" value="Residency Permit Form" />
                    <input className="half-input" type="text" name="first-name" placeholder="First Name*" required />
                    <input className="half-input" type="text" name="last-name" placeholder="Last Name*" required />
                    <input className="half-input" type="email" name="email" placeholder="Email*" required />
                    <input className="half-input" type="tel" name="phone" placeholder="Phone Number*" required />
                    <input type="text" name="message" placeholder="Message" />
                    {successForm
                        ? <h4 style={sentButtonStyles}>Thank you!<br />Your Messages has been sent successfully.<br />We will get back to you soon.</h4>
                        : ""
                    }
                    <button className="clear-btn" style={sentButtonStyles} type="submit">SUBMIT</button>

                </form>



            </div>
        </div>
    )
}

export default ResidencyPermit;