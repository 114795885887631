import React, { useState, useEffect } from 'react'
import './property-page.scss'
import { graphql } from 'gatsby'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import Layout from '../components/Layout/layout'
import PopupForm from '../components/PopupForm/popup-form'
import PropertyDetailTopContent from "../components/PropertyDetail/PropertyDetailTopContent/PropertyDetailTopContent"
import PropertyDetailLightbox from "../components/PropertyDetail/PropertyLightbox/PropertyDetailLightbox"
import PropertyDetailContent from "../components/PropertyDetail/PropertyDetailContent/PropertyDetailContent"
import SimilarProperty from "../components/PropertyDetail/SimilarProperty/similar-property"


export const query = graphql`
    query($slug: String!, $minprice: Int!, $maxprice: Int!) {
        property: contentfulCommercialProperties(slug: {eq: $slug}) {
            seo {
                metaTitle
                metaDescription {
                    metaDescription
                }
            }
            propertyImages {
              gatsbyImageData(
                aspectRatio: 1.5
                cropFocus: BOTTOM
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 1900
              )
              title
              file {
                url
                fileName
                contentType
              }
            }
            images{
              url
            }
            provider
            propertyTitle
            propertyDescription {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
              }
            }
            propertyId
            propertyPrice
            pricePerSqm
            propertyType
            slug
            sizeInSquareMeters
            paymentFrequency
            numberOfBathrooms
            numberOfBedrooms
            numberOfParking
            agent {
              name
              email
              phoneNumber
              jobTitle
              address {
                address
              }
              image {
                gatsbyImageData(
                  aspectRatio: 1.5
                  cropFocus: BOTTOM
                  layout: FULL_WIDTH
                  placeholder: BLURRED  
                )
                title
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
        }
        similarProperty: allContentfulCommercialProperties (limit: 10, filter: {propertyPrice:{gte : $minprice, lte : $maxprice}}) {
          edges {
            node {  
              seo {
                  metaTitle
                  metaDescription {
                      metaDescription
                  }
              }
              propertyImages {
                gatsbyImageData(
                  aspectRatio: 1.5
                  cropFocus: BOTTOM
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  width: 1900
                )
                title
                file {
                  url
                  fileName
                  contentType
                }
              } 
              images{
                url
              }
              provider            
              propertyTitle
              propertyOneLiner
              propertyDescription {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    gatsbyImageData(
                      aspectRatio: 1.5
                      cropFocus: CENTER
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                    )
                    title
                  }
                }
              }
              propertyId
              propertyPrice
              pricePerSqm
              propertyType
              slug
              sizeInSquareMeters
              paymentFrequency
            }
          } 
        }
        agent: contentfulAgents(email: {eq: "marketing@25spaces.com"}) {
          name
          email
          phoneNumber
          jobTitle
          address {
            address
          }
          image {
            gatsbyImageData(
              aspectRatio: 1.5
              cropFocus: BOTTOM
              layout: FULL_WIDTH
              placeholder: BLURRED  
            )
            title
            file {
              url
              fileName
              contentType
            }
          }
        }
    }
`

const PropertyPage = ({ data: { property, similarProperty, agent } }) => {

  const [openRequestForm, setOpenRequestForm] = useState(false)
  const [openViewingForm, setOpenViewingForm] = useState(false)
  const [windowLocation, setWindowLocation] = useState()


  const handleOpenRequestForm = () => {
    setOpenRequestForm(true)
  }
  const handleCloseRequestForm = () => {
    setOpenRequestForm(false)
  }

  const handleOpenViewingForm = () => {
    setOpenViewingForm(true)
  }
  const handleCloseViewingForm = () => {
    setOpenViewingForm(false)
  }

  useEffect(() => {
    setWindowLocation(window.location.href);
  })


//function to remove the REF ID from propertyTitle for displaying meta title
let slicedTitle = property?.propertyTitle.replace(/(.*?) REF.*/i, "$1")  
//console.log("slicedTitle",slicedTitle)

var propertyTitle = slicedTitle.toLowerCase() 

//function to capitalize the first letter of each word
const words = propertyTitle.split(" ");

for (let i = 0; i < words.length; i++) {
  words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
}
var capitalizepropertyTitle = words.join(" ");

var seoTitle = `${capitalizepropertyTitle}  25 Spaces Real Estate`
var seoDesc = `${capitalizepropertyTitle.replace("|" , "")}. Contact 25 Spaces Real Estate for more information about the property.`

  return (

    <Layout className="property-detail commercial">

      <SeoHelmet
        metaTitle={seoTitle}
        metaDescription={seoDesc}
        shareImage={property.propertyImages}
        images={property.images}
        provider={property.provider}
        shareUrl={windowLocation}
        shareTitle={property.propertyTitle}
      ></SeoHelmet>
      <PropertyDetailLightbox
        propertyImages={property.propertyImages}
        images={property.images}
        isMobile={true}
        provider={property.provider}
      />
      <PropertyDetailTopContent
        title={property.propertyTitle}
        price={property.propertyPrice}
        category={property.propertyCategory}
        type={property.propertyType}
        paymentFrequency={property.paymentFrequency}
        pricePerSQM={property.pricePerSqm}
        beds={property.numberOfBedrooms}
        baths={property.numberOfBathrooms}
        parking={property.numberOfParking} 
        sqmeters={property.sizeInSquareMeters}
        openViewing={handleOpenViewingForm}
      />
       <PropertyDetailLightbox
        propertyImages={property.propertyImages}
        images={property.images}
        isMobile={false}
        provider={property.provider}
      /> 
      <PropertyDetailContent
        openRequest={handleOpenRequestForm}
        openViewing={handleOpenViewingForm}
        shareUrl={windowLocation}
        agentDefault={agent}
        {...property}
      />
      <SimilarProperty similarProperty={similarProperty?.edges} isCommercial={true} />


      <PopupForm
        closeForm={handleCloseRequestForm}
        openForm={openRequestForm}
        formTitle="Request Details for"
        formIntro={property.propertyTitle}
      ></PopupForm>

      <PopupForm
        closeForm={handleCloseViewingForm}
        openForm={openViewingForm}
        formTitle="Schedule a Viewing for"
        formIntro={property.propertyTitle}
      ></PopupForm>
    </Layout>

  )
}

export default PropertyPage