import React, { useEffect, useState } from 'react'
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql, Link } from 'gatsby'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import minus from '../../images/minus.png'
import plus from '../../images/plus.png'

const PopularSearch = () => {
    let location = useLocation();
    const data = useStaticQuery(graphql`
        query {
            popularSearch: allContentfulPopularSearches {
                edges {
                    node {
                        title
                        type
                        searchLists {
                            linkName
                            linkUrlAddress
                        }
                    }
                }
            } 
        }
    `);

    const [popularSearchList, setPopularSearchList] = useState([])
    const [expanded, setExpanded] = useState(false)
    const popularSearch = data.popularSearch.edges;

    useEffect(() => {
        if (location) {
            let type = "static"
            if (location.pathname.indexOf("properties/type-rent") > -1) {
                type = "rent"
            } else if (location.pathname.indexOf("properties/type-buy") > -1) {
                type = "sale"
            }
            const result = popularSearch.find(({ node }) => node.type === type);
            const chunkSize = 5;
            let searchList = [];
            for (let i = 0; i < result.node.searchLists.length; i += chunkSize) {
                const chunk = result.node.searchLists.slice(i, i + chunkSize);
                searchList.push(chunk)
            }
            setPopularSearchList(searchList)
        }
    }, [location])

    return (
        <div className="popular-search">
            <div className="contain">
                <div className='title'>
                    <h4>Popular Searches </h4>
                    <div className='close' onClick={() => { setExpanded(!expanded) }}>
                        {expanded ?
                            <img src={minus} className={"minus"}  />
                            :
                            <img src={plus}  className={"plus"}  />
                        }
                    </div>
                </div>
                {expanded &&
                    <div className="menu-container">
                        {popularSearchList.map((search, k) => {
                            return (
                                <div className="menu-list-container">
                                    <ul key={k}>
                                        {search.map((list, l) => {
                                            return (<li key={l}> <Link to={list.linkUrlAddress}>{list.linkName}</Link> </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>

        </div>
    )
}

export default PopularSearch