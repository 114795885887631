import React, {useState, useEffect} from 'react'
import './guide-tab-section.scss'

import { StaticQuery, graphql} from 'gatsby'


import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>
const Heading4 = ({ children }) => <h4>{children}</h4>


const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text>{children}</Text>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Heading3>{children}</Heading3>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return(
            <Heading4>{children}</Heading4>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
        )
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }


const GuideTabSection = () => {

    const [ tabId, setTabId ] = useState();
    const [ tabContents, setTabContents ] = useState([]);
    const [ animateMobileTab, setAnimateMobileTab ] = useState(false);

    // useEffect(() => {
    //     const contents = [...document.querySelectorAll('.tab-full-content')];
    //     setTabContents(contents);
    //     contents[0].classList.add('animate');
    // }, [])

    useEffect(() => {

        const contents = [...document.querySelectorAll('.tab-full-content')];
        setTabContents(contents);
        contents[0].classList.add('animate');

        tabContents.forEach((content) => {
            if(content.id === tabId){
                content.classList.add('animate')
            } else {
                content.classList.remove('animate');
            }
        })



    }, [tabId])

    useEffect(() => {
        for(let mobileTab of tabContents) {
            const mobileTabButton = mobileTab.querySelector('.span-btn');
            const mobileTabImage = mobileTab.querySelector('.tab-image');
            const mobileTabContent = mobileTab.querySelector('.tab-content');

            const tabAnimate = () => {
                const allTabs = document.getElementsByClassName('tab-full-content');
                for(let all of allTabs) {
                    all.querySelector('.span-btn').classList.remove('selected');
                    all.querySelector('.tab-image').classList.remove('animate');
                    all.querySelector('.tab-content').classList.remove('animate');
                }
                mobileTabButton.classList.add('selected');
                mobileTabImage.classList.add('animate');
                mobileTabContent.classList.add('animate');
            }
            mobileTabButton.addEventListener('click', tabAnimate)
        }
    }, [animateMobileTab, tabContents])

    const tabClick = (label) => {
        setTabId(label)
    }

    const mobileTabClick = () => {
        setAnimateMobileTab(true)
    }


    return (
        <StaticQuery
            query = { graphql`
                query {
                    tabs: allContentfulPageSubsection(filter: {slug: {eq: "the-pearl-qatar"}}) {
                        edges {
                        node {
                            otherElements {
                                ...on ContentfulTabs {
                                    slug
                                    tabContent {
                                        raw
                                    }
                                    title
                                    image {
                                        gatsbyImageData(
                                            aspectRatio: 1.5
                                            cropFocus: CENTER
                                            layout: FULL_WIDTH
                                            width: 1000
                                            height: 1500
                                            placeholder: BLURRED
                                        )
                                        title
                                    }
                                }
                            }
                        }
                        }
                    }
                }
            `}
            render = {data => (
                <section className="tabs-section">

            <div className="section-subtitle-container">
                <h3>Divided into ten architecturally distinct precincts</h3>
            </div>

            <div className="tabs-main-container">

                <div className="tab-buttons-container">
                    {data.tabs.edges[0].node.otherElements.map((tab, i) => {
                        const slug = tab.slug
                        return(
                            <button key={i} className="tab-button" onClick={() => tabClick(slug)}>{tab.title}</button>
                        )
                    })}
                </div>
              

                <div className="tab-content-containers">
                
                    {data.tabs.edges[0].node.otherElements.map((content, i) => {
                        return(
                            <div key={i} className="tab-full-content" id={content.slug}>
                            
                                <div className="mobile-tab-btn">
                                    <span role="button" aria-label="tab" tabIndex="0" className="span-btn" onClick={mobileTabClick} onKeyDown={mobileTabClick}>{content.title}</span>
                                </div>

                                <div className="tab-image">
                                    <GatsbyImage image={getImage(content.image)} alt={content.image.title} />
                                </div>

                                <div className="tab-content">
                                    {content.tabContent && renderRichText(content.tabContent, options)}
                                </div>
                            </div>
                        )
                    })}

                    
                </div>

            </div>

        </section>
            )}
        />  
    )
}

export default GuideTabSection