import React from 'react';
import BlogCard from './BlogCard';
import "../RelatedPosts/RelatedPosts.scss"


const RelatedPosts = (props) =>{
  
    return(
       props?.RelatedPosts && 
       <div className="related-posts-section">
        <section className='other-blogs-section'>  
                    <h2 className='blog-main-heading'>Other posts that may interest you</h2>
                    <div className='blogs-card-section'>
                         {props?.RelatedPosts?.map((item ,i) => {
                          console.log("itemm",item)
                        return(
                             <BlogCard
                                key={i}
                                slug={item?.slug}
                                BlogImage={item?.postMainImage}
                                BlogTitle={item?.postTitle}
                                BlogDate = {item?.postDate}
                                isBlogPage = {true}
                             />
                        )
                    })}
                    </div>
                       
        </section>
        </div>
                
    )}
    

export default RelatedPosts;
