import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>
const Heading4 = ({ children }) => <h4>{children}</h4>
const Heading5 = ({ children }) => <h5>{children}</h5>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text>{children}</Text>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Heading3>{children}</Heading3>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return(
            <Heading4>{children}</Heading4>
        )
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return(
            <Heading5>{children}</Heading5>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          // <img src={node.data.target.fixed.src} alt={node.data.target.title}/>
          <GatsbyImage image={getImage(node.data.target)} alt={node.data.target.title} />
        )
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }

const SearchAreaContent = ({ searchType, location }) => {
    const removeStandEnd = (slugPath, char) => {
        let path = slugPath;
        if (slugPath.startsWith(char)) {
            path = slugPath.slice(1)
        }
        if (slugPath.endsWith(char)) {
            path = path.slice(0,-1)
        }
        return path;
    }
    const searchResultConent = (data) => {
        let findContent = data.find((con) => con.node.type === (searchType === "Buy") && removeStandEnd(con.node.slug, '/') == removeStandEnd(
            location.pathname, '/')); 
        return findContent;
    }
    return (
        <StaticQuery
            query={graphql`
                query {
                    searchContent: allContentfulSearchResultContent {
                        edges {
                            node {
                                    title
                                    type
                                    slug
                                    content {
                                      raw
                                      references {
                                        ... on ContentfulAsset {
                                            contentful_id
                                            __typename
                                            gatsbyImageData(
                                                aspectRatio: 1.5
                                                cropFocus: CENTER
                                                layout: CONSTRAINED
                                                placeholder: BLURRED
                                              )
                                              title
                                            }
                                        }
                                    }
                                }
                              }
                      }
                    
                }
            `}
            render={data => {
             
                let searchResultCon = searchResultConent(data.searchContent.edges)
                //console.log('searchResultCon', searchResultCon)
                if (searchResultCon) {
                    return (
                        <section className="search-content-section">
                            {searchResultCon.node.content &&
                                renderRichText(searchResultCon.node.content , options)
                            }
                           
                        </section>
                    )
                }

            }
            }
        />
    )
}
export default SearchAreaContent