import React, { useState, useEffect } from 'react'
import Layout from "../components/Layout/layout";
import SeoHelmet from "../components/SeoHelmet/seo-helmet";
import TransparentButton from "../components/buttons/TransparentButton/transparent-button";
import "../pages/404.scss";
import { useLocation } from "@reach/router"

const NotFoundPage = () => {
  const [isPropertyPage, setIsPropertyPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (location.pathname.includes("/property-for-sale/")) {
        window.location.href = "/properties/type-buy/";
      } else if (location.pathname.includes("/property-for-rent/")) {
        window.location.href = "/properties/type-rent/";
      } else {
        setIsPropertyPage(true);
      }
    }
  }, [location.pathname]);

  return (
    <>
      {isPropertyPage &&
        <Layout className="Notfoundpage">
        <SeoHelmet
          metaTitle="404 : Notfound page"
          metaDescription="404 : Notfound page"
        ></SeoHelmet>
        <div className="error-page">
          <div className="error-container">
            <h1>Sorry, the page you're after seems to be missing</h1>
            <p>Looks like you have followed a broken link or entered a URL that doesn't exist on this site.</p>
            <div className='error-page-btn-section'>
              <TransparentButton
                href=""
                buttonText="Homepage"
                isPrimary
              ></TransparentButton>
              <TransparentButton
                href="contact-us"
                buttonText="Contact Us"
                isPrimary
              ></TransparentButton>
            </div>
          </div>
  
        </div>
      </Layout>
      }
    </>
  )
}
export default NotFoundPage;