import React, { useState, useEffect } from 'react' 
import './header.scss'

import { Link } from 'gatsby'


import HeaderNav from '../HeaderNav/header-nav'

const Header = (props) => {


    const [scrollAnimate, setScrollAnimate] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener('scroll', () =>
                setScrollAnimate(window.pageYOffset > 700)
            );
        }
    }, []);

    return (
        <header className={`header ${scrollAnimate ? 'animate' : ''} ${props.homepageHeader}`}>
            <div className='logo-wrapper'>
            <div className="logo-container">
                <Link to='/'><img
                    src={props.logo}
                    alt="25 Spaces real estate" 
                    width={150}
                    height={60}
                /></Link>
              
                
            </div>
            <div className='leave-your-space'>
              <span>  Leave your living space to us!</span>
            </div>
        </div>
            <HeaderNav></HeaderNav>
            <div role="button" tabIndex="0" onClick={props.handleAnimate} onKeyDown={props.handleAnimate} className="nav-burger-btn-container">
                <div className="nav-burger-btn">
                    <p>Menu</p>
                </div>
            </div>
        </header>
    )
}

export default Header