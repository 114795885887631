import React from 'react'
import './PageTitle.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const PageTitle = (props) => {
    //console.log("propssss",props)
    const d = new Date(props?.publishedDate)
    var date = d.toLocaleDateString("en-UK",  {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const handleBack = () => {
        window.history.back();
      }
    return (
        <section className={(props.className) ? props.className : 'full-width-title-section'}>
            <div className="general-page-title">
              {props.isBlogPage?
                 <div className="back-button">
                      <a href="javascript:void(0);" onClick={handleBack} onKeyPress={handleBack} className="vertical-dark-btn">{`< Back to the Blogs`}</a>
                </div> : ""
            }
           

                {/* {props.isBlogPage ? <p className='blog-published-date'>{date}</p> : ""} */}
                {props.isBlogPage ? <h1 className='blog-title'>{props.title}</h1> : <h2>{props.title}</h2>}
                {/* <h3>{props.title}</h3> */}
                {props.subtitle
                    ? <p className='general-page-subtitle'>{props.subtitle}</p>
                    : ""
                }
                {props.authorImage && props.authorName && props.isBlogPage ? 
                  <div className='author-image-section'>
                    <div className='author-image'>
                      <GatsbyImage image={getImage(props?.authorImage)} alt={props?.altName}/>
                    </div>
                     <span className='author-name'>by <Link to={props?.linkedInURL} target="blank">{props?.authorName}</Link></span>
                  </div>
                
                : ""}
            </div>
        </section>
    )
}

export default PageTitle