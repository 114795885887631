import React from 'react'
import './doha-neighborhoods-guide.scss'
import Layout from '../components/Layout/layout'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import PageSubsection from '../components/PageSubsection/page-sub-section'
import PageContent from '../components/PageSubsection/page-content'

import GuideTabSection from '../components/GuideTabSection/guide-tab-section'
import SectionTitle from '../components/SectionTitle/section-title'
import ServiceRichText from '../components/ServiceRichText/service-rich-text'
import FeaturesRound from '../components/FeaturesRound/features-round'
import BasicSection from '../components/BasicSection/basic-section'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const query = graphql`
    {
        guide: allContentfulGuides(filter: {slug: {eq: "doha-suburbs-guide"}}) {
            edges {
              node {
                id
                seo {
                    metaTitle
                    metaDescription {
                        metaDescription
                    }
                }
                bannerHeading
                neighborhoods {
                  slug
                  id
                  sectionTitle
                  sectionShortText {
                    raw
                  }
                  sectionText {
                    raw
                  }
                  sectionImage {
                    gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: CENTER
                        layout: CONSTRAINED
                        width: 1200
                        placeholder: BLURRED
                      )
                      title
                  }
                }
                pageMainImage {
                    gatsbyImageData(
                        aspectRatio: 1.25
                        cropFocus: CENTER
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        width: 1900
                      )
                      title
                }
                title
                titleFirstBreak
                titleSecondBreak
                content{
                    raw
                }
                pageHeaderImage {
                    gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: TOP
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                      )
                      title
                  }
                faqContentModule {
                    raw
                }
              }    
            }
          }
    }
`

const DohaSuburbsGuide = ({ data: { guide } }) => {
    return (
        <Layout>

            <SeoHelmet
                metaTitle={guide.edges[0].node.seo.metaTitle}
                metaDescription={guide.edges[0].node.seo.metaDescription.metaDescription}
                ogimage = {guide?.edges[0]?.node.pageHeaderImage}
            ></SeoHelmet>

            <GeneralPageSubHeader
                image={guide.edges[0].node.pageHeaderImage}
                imageAlt={guide.edges[0].node.pageHeaderImage.title}
                breadcrumb="Home - Buyers - "
                pageName={guide.edges[0].node.title}
                bannerHeading={guide?.edges[0]?.node?.bannerHeading}
            ></GeneralPageSubHeader>

            <section className="guides-title-and-nav-section">
                <div className="title-and-nav-container">

                    <SectionTitle
                        sectionNumber=""
                        sectionTitle={guide.edges[0].node.titleFirstBreak}
                        sectionTitleSpan={guide.edges[0].node.titleSecondBreak}
                    ></SectionTitle>


                    <ul className="guides-nav-container">
                        {guide.edges[0].node.neighborhoods.map((section, i) => {
                            return (
                                <li key={i}>
                                    <a href={`#${section.slug}`} className="dark-btn">{section.sectionTitle}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="image-container">
                    <div className="image">
                        <GatsbyImage image={getImage(guide.edges[0].node.pageMainImage)} alt={guide.edges[0].node.pageMainImage.title} />
                    </div>
                </div>

            </section>
            <section className='guide-content'>
                <PageContent
                    sectionId={guide.edges[0].node.id}
                    className='content-'
                    key={guide.edges[0].node.id}
                    sectionText={guide.edges[0].node.content}
                ></PageContent>
            </section>

            <section className="guides-content-section">
                {guide.edges[0].node.neighborhoods.map((list, key) => {
                    return (
                        <PageSubsection
                            sectionId={list.slug}
                            className='left-image'
                            key={list.id}
                            subtitle={list.sectionTitle}
                            sectionText={list.sectionShortText}
                            image={list.sectionImage}
                            imageAlt={list.sectionImage.title}
                            btnLabel={`Find Out More`}
                            btnLink={`/doha-neighborhoods/${list.slug}/`}
                        ></PageSubsection>
                    )

                })}
            </section>
           {guide.edges[0].node.faqContentModule &&
                        <section className='faq-content-section'>
                                <ServiceRichText
                                        // shortText={service.serviceShortText}
                                        richText={guide.edges[0].node.faqContentModule}
                                    >
                                    </ServiceRichText>
                                    
                                    
                        </section>}
                      
            
        </Layout>
    )
}

export default DohaSuburbsGuide