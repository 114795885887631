import React from 'react'
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'

import { graphql } from 'gatsby'
import SectionTitle from '../components/SectionTitle/section-title'
import ImageContainer from '../components/ImageContainer/image-container'
import PageSubsection from '../components/PageSubsection/page-sub-section'

import SeoHelmet from '../components/SeoHelmet/seo-helmet.js'

export const query = graphql`
    {
        spaces: allContentfulAbout25SpacesPage {
            edges {
              node {
                seo {
                  metaTitle
                  metaDescription {
                    metaDescription
                  }
                }
                bannerHeading
                pageHeaderImage {
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    cropFocus: BOTTOM
                    layout: FULL_WIDTH
                  )
                  title
                }
                pageImage {
                  gatsbyImageData(
                    width: 1000
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    cropFocus: CENTER
                  )
                  title
                }
                pageSlogan
                slug
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                  introText
                }
                pageSubsections {
                  sectionTitle
                  sectionText {
                    raw
                  }
                  sectionImage {
                    gatsbyImageData(
                      width: 1000
                      cropFocus: CENTER
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    title
                  }
                }
              }
            }
          }
    }
`

const SpacesRealEstate = ({ data: {spaces}}) => {
 
    return (
        <Layout>

            <SeoHelmet
              metaTitle = {spaces.edges[0].node.seo.metaTitle}
              metaDescription = {spaces.edges[0].node.seo.metaDescription.metaDescription}
              ogimage = {spaces?.edges[0]?.node.pageHeaderImage}
            ></SeoHelmet>

            <GeneralPageSubHeader
                image={spaces.edges[0].node.pageHeaderImage}
                imageAlt={spaces.edges[0].node.pageHeaderImage.title}
                bannerHeading={spaces?.edges[0]?.node?.bannerHeading}
                breadcrumb = "Home - About Us - "
                pageName = "25 Spaces Real Estate"
            ></GeneralPageSubHeader>

            <section className="full-width-section">
                <div className="generic-page-content">
                    <SectionTitle
                        sectionNumber=""
                        sectionTitle={spaces.edges[0].node.titleFirstBreak}
                        sectionTitleSpan = {spaces.edges[0].node.titleSecondBreak}
                    ></SectionTitle>
                    <div className="generic-page-text">
                        <p>{spaces.edges[0].node.introText.introText}</p>
                        <h4 className="red-heading">{spaces.edges[0].node.pageSlogan}</h4>
                    </div>
                </div>

                <ImageContainer
                    image = {spaces.edges[0].node.pageImage}
                    imageAlt = {spaces.edges[0].node.pageImage.title}
                ></ImageContainer>

            </section>

            {spaces.edges[0].node.pageSubsections.map((section, i) => {
                return (
                    <PageSubsection
                        subtitle = {section.sectionTitle}
                        sectionText = {section.sectionText}
                        image = {section.sectionImage}
                        imageAlt = {section.sectionImage.title}
                    />
                )
            })}




        </Layout>
    )
}

export default SpacesRealEstate