import React from 'react'
import BasicSection from '../components/BasicSection/basic-section'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
import ListingThumbnailNewDevelopments from '../components/ListingThumbnail/listing-thumbnail-newdevelopments'
import SectionTitle from '../components/SectionTitle/section-title'
import TwoColumnsInnerSection from '../components/TwoColumnsInnerSection/two-columns-inner-section'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { graphql } from 'gatsby'
// import IntroSideNav from '../components/IntroSideNav/intro-side-nav'
import TestimonialsSmall from '../components/TestiomonialsSmall/testimonials-small'
import PageSubsection from '../components/PageSubsection/page-sub-section'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'
import PageIntro from '../components/PageIntro/page-intro'
import PropertyCard from '../components/PropertyCard/PropertyCard';
import logo from '../images/logo-color@2x.png'
import "../pages/new-developments.scss"

export const query = graphql`
    {
        properties: allContentfulProperty(filter: {
        isThisANewDevelopment: {eq: true}       
        } sort: {fields: createdAt, order: DESC}) {
            edges {
              node {
                createdAt
                propertyImages {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    width: 600
                    height: 500
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1
                    )
                    title
                }
                images{
                  url
                }
                isThisANewDevelopment
                provider
                propertyPrice
                propertyType
                neighborhood {
                  title
                  slug
                }
                slug
                propertyOneLiner
              }
            }
        }
        gewan_properties: allContentfulProperty(filter: {
          neighborhood: {title: {eq: "Gewan Island, Doha"}}
        } sort: {fields: createdAt, order: DESC}) {
            edges {
              node {
                createdAt
                propertyImages {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    width: 600
                    height: 500
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1
                    )
                    title
                }
                images{
                  url
                }
                isThisANewDevelopment
                provider
                propertyPrice
                propertyType
                neighborhood {
                  title
                  slug
                }
                slug
                propertyOneLiner
              }
            }
        }
        newDevelopments: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "New Developments"}}) {
            edges {
              node {
                id
                bannerHeading
                bannerText
                bannerLongText {
                  bannerLongText
                }
                bannerButtonOneLink {
                linkUrlAddress
                }
                bannerButtonOneText
                bannerButtonTwoLink {
                linkUrlAddress
                }
                bannerButtonTwoText
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                  raw
                }
                pageHeaderImage {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
                pageImage {
                  ...on ContentfulAsset {
                      contentful_id
                      __typename
                      gatsbyImageData(
                      aspectRatio: 1.5
                      cropFocus: CENTER
                      placeholder: BLURRED
                      width: 1000
                    )
                    title
                  }
                }
              }
            }
          }
          about: allContentfulPageSubsection(filter: {sectionTitle: {eq: "About Us"}}) {
            edges {
            node {
                id
                sectionImage {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 600
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1.5
                    )
                    title
                }
                sectionTitle
                sectionText {
                raw
                }
                slug
            }
            }
        }
        strategy: allContentfulPageSubsection(filter: {sectionTitle: {eq: "Our Strategy"}}) {
          edges {
          node {
              id
              sectionImage {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 600
                  cropFocus: CENTER
                  placeholder: BLURRED
                  aspectRatio: 1.5
                  )
                  title
              }
              sectionTitle
              sectionText {
              raw
              }
              slug
          }
          }
      }
    }
`


const NewDevelopments = ({ data: { properties, gewan_properties, newDevelopments, about, strategy } }) => {
 
  return (
    <Layout className="new-developments">

      <SeoHelmet
        metaTitle= "New Developments in Qatar | 25 Spaces Real Estate"
        metaDescription="Your guide to new and upcoming developments here in Doha, Qatar containing detailed and current information on new housing developments, so you can better understand trends and manage your risk in today’s real estate markets, and into the future."
        ogimage = {newDevelopments?.edges[0]?.node.pageHeaderImage}
      ></SeoHelmet>
      <GeneralPageSubHeader
        image={newDevelopments.edges[0].node.pageHeaderImage}
        imageAlt={newDevelopments.edges[0].node.pageHeaderImage.title}
        breadcrumb="Home - Properties - "
        pageName={newDevelopments.edges[0].node.title}
        bannerHeading={newDevelopments.edges[0].node.bannerHeading}
        bannerText={newDevelopments.edges[0].node.bannerText}
        bannerLongText={newDevelopments?.edges[0]?.node?.bannerLongText?.bannerLongText}
        bannerButtonOneLink={newDevelopments.edges[0].node.bannerButtonOneLink}
        bannerButtonOneText={newDevelopments.edges[0].node.bannerButtonOneText}
        bannerButtonTwoLink={newDevelopments.edges[0].node.bannerButtonTwoLink}
        bannerButtonTwoText={newDevelopments.edges[0].node.bannerButtonTwoText}
      ></GeneralPageSubHeader>
      <BasicSection>
        <SectionTitle
          sectionNumber=""
          sectionTitle={newDevelopments.edges[0].node.titleFirstBreak}
          sectionTitleSpan={newDevelopments.edges[0].node.titleSecondBreak}
        ></SectionTitle>
      </BasicSection>

      {properties.edges.length > 0
        ? <section className='basic-section property-section'>

          {properties.edges.map((property, i) => {
            return (
              <PropertyCard
                key={i}
                propertydata={property.node}
                price={property.node.propertyPrice}
                neighborhood={property.node.neighborhood?.title}
                slug={`${property.node.slug}`} 
                category={property.node.propertyCategory}
                oneLiner={property.node.propertyOneLiner}
                type={property.node.propertyType}
                paymentFrequency={property.node.paymentFrequency}
                isCommercial={false}
                isThisANewDevelopment={property.node.isThisANewDevelopment}
                provider={property.node.provider}
              />

            )
          })}
          {gewan_properties.edges.length > 0 ? <>

              {gewan_properties.edges.map((property, i) => {
                return (
                  <PropertyCard
                    key={i}
                    propertydata={property.node}
                    price={property.node.propertyPrice}
                    neighborhood={property.node.neighborhood?.title}
                    slug={`${property.node.slug}`} 
                    category={property.node.propertyCategory}
                    oneLiner={property.node.propertyOneLiner}
                    type={property.node.propertyType}
                    paymentFrequency={property.node.paymentFrequency}
                    isCommercial={false}
                    isThisANewDevelopment={property.node.isThisANewDevelopment}
                    provider={property.node.provider}
                  />

                )
              })}
            </>
            : ""
          } 
        </section>
        : ""
      } 
      

      <section className="full-width-section pt-0 top-section">
        <div className="generic-page-content">
          <PageIntro
            introText={newDevelopments.edges[0].node.introText}
          ></PageIntro>
        </div>
        <GenericPageImageContact
          image={newDevelopments.edges[0].node.pageImage}
          logo={logo}
        ></GenericPageImageContact>
      </section>

      


      <PageSubsection
        subtitle={about.edges[0].node.sectionTitle}
        sectionText={about.edges[0].node.sectionText}
        image={about.edges[0].node.sectionImage}
        imageAlt={about.edges[0].node.sectionImage.title}
      ></PageSubsection>

      <PageSubsection
        subtitle={strategy.edges[0].node.sectionTitle}
        sectionText={strategy.edges[0].node.sectionText}
        image={strategy.edges[0].node.sectionImage}
        imageAlt={strategy.edges[0].node.sectionImage.title}
      ></PageSubsection>

      <TestimonialsSmall></TestimonialsSmall>
    </Layout>
  )
}

export default NewDevelopments