import React from 'react';
import './home-in-the-news.scss';
import SectionWithBackground from '../SectionWithBackground/section-with-background'
import SectionTitle from '../SectionTitle/section-title'
import WhiteToRedButton from '../buttons/WhiteToRedButton/white-to-red-button'

import { StaticQuery, graphql } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p className="section-intro-text white">{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text>{children}</Text>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Heading3>{children}</Heading3>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
        )
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }


const HomeInTheNews = () => {
    return (
        <StaticQuery
            query = {graphql`
                query {
                    news: allContentfulHomepageElements(filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}, limit: 2) {
                        edges {
                            node {
                                featuredNewsblogs {
                                ... on ContentfulBlogPost {
                                    __typename
                                    id
                                    postTitle
                                    postShortDescription {
                                        postShortDescription
                                        }
                                    postMainImage {
                                        gatsbyImageData(
                                            aspectRatio: 1
                                            cropFocus: CENTER
                                            placeholder: BLURRED
                                            resizingBehavior: FILL
                                        )
                                        title
                                    }
                                    slug
                                }
                                ... on ContentfulNewsPost {
                                    __typename
                                        id
                                        newsImage {
                                            gatsbyImageData(
                                                aspectRatio: 1
                                                cropFocus: CENTER
                                                placeholder: BLURRED
                                                resizingBehavior: FILL
                                            )
                                            title
                                        }
                                        newsDescription {
                                            newsDescription
                                            }
                                        externalPage
                                        title
                                    }
                                }
                            }
                        }
                    }
                    intro: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "In The News"}}) {
                        edges {
                        node {
                            id
                            bannerHeading
                            bannerText
                            bannerButtonOneLink {
                            linkUrlAddress
                            }
                            bannerButtonOneText
                            bannerButtonTwoLink {
                            linkUrlAddress
                            }
                            bannerButtonTwoText
                            title
                            titleFirstBreak
                            titleSecondBreak
                            slug
                            introText {
                            raw
                            }
                        }
                        }
                    }
                }
            `}
            render = { data => (
                <SectionWithBackground>
                    <div className="homepage-inthenews-container">
                        
                        <div className="section-header-container">
                            <div className="section-header">
                            <SectionTitle
                                theme = "white"
                                sectionNumber = "02"
                                sectionTitle = "Blogs"
                                sectionTitleSpan = ""
                            ></SectionTitle>
                                {data.intro.edges[0].node.introText && renderRichText(data.intro.edges[0].node.introText, options)}
                                <WhiteToRedButton
                                    //href = {data.intro.edges[0].node.slug}
                                    href= "/blogs/"
                                    buttonText="Read More +"></WhiteToRedButton>
                            </div>
                        </div>

                        <div className="inthenews-blocks-container">

                        {data.news.edges.map( (single) => 
                            single.node.featuredNewsblogs.map( (featured, i) => {
                                if(featured.__typename === "ContentfulBlogPost") {
                                    return(
                                        <div key={i} className="inthenews-block">
                                            <div className="inthenews-block-intro">
                                                <h3 className="white">{featured.postTitle}</h3>
                                                <p className="white">{featured.postShortDescription.postShortDescription}</p>
                                                <WhiteToRedButton
                                                    href={`/blog/${featured.slug}`}
                                                    buttonText="Read More +"></WhiteToRedButton>
                                            </div>
                                            <div className="inthenews-block-image">
                                            <GatsbyImage className='block-image' image={getImage(featured.postMainImage)} alt={featured.postMainImage.title} />
                                            </div>
                                        </div>

                                    )
                                } else if (featured.__typename === "ContentfulNewsPost") {
                                    return (
                                        <div key={i} className="inthenews-block">
                                            <div className="inthenews-block-intro">
                                                <h3 className="white">{featured.title}</h3>
                                                <p className="white">{featured.newsDescription.newsDescription}</p>
                                                <WhiteToRedButton
                                                    href={featured.externalPage}
                                                    buttonText="Read More +"></WhiteToRedButton>
                                            </div>
                                            <div className="inthenews-block-image">
                                            <GatsbyImage className='block-image' image={getImage(featured.newsImage)} alt={featured.newsImage.title} />
                                            </div>
                                        </div>

                                    )
                                }
                            })
                        )}
                        </div>
                        
                    </div>
                </SectionWithBackground>
            )}
        />
    )
}



export default HomeInTheNews;