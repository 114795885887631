import React, { useState } from 'react'
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import SectionTitle from '../components/SectionTitle/section-title'
// import ImageContainer from '../components/ImageContainer/image-container'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'
import logo from '../images/logo-color@2x.png'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { graphql } from 'gatsby'

import axios from 'axios'

export const query = graphql`
    {
        contact: allContentfulSiteGeneralSettings {
            edges {
              node {
                contactHeaderImage {
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                  )
                  title
                }
                contactPageImage {
                  gatsbyImageData(
                    cropFocus: CENTER
                    layout: FULL_WIDTH
                    width: 1000
                    placeholder: BLURRED
                  )
                }
                bannerHeading
                bannerButtonOneText
                bannerButtonTwoText
                bannerButtonOneLink{
                  linkUrlAddress
                }
                bannerButtonTwoLink{
                  linkUrlAddress
                }
              }
            }
          }
    }
`

const sentButtonStyles = {
  marginTop: "30px",
}


const ContactUs = ({ data: { contact } }) => {

  const [successForm, setSuccessForm] = useState(false)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
      setSuccessForm(true)
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/777c5d3d-1388-4aa2-9224-8e0a464d44ac",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };



  return (
    <Layout>

      <SeoHelmet
        metaTitle="25 Spaces Real Estate - Contact Us"
        metaDescription="We would love to hear from you! Send us a message and we’ll get right back in touch."
        ogimage = {contact?.edges[0]?.node.contactHeaderImage}
      ></SeoHelmet>

      <GeneralPageSubHeader
        image={contact.edges[0].node.contactHeaderImage}
        imageAlt={contact.edges[0].node.contactHeaderImage.title}
        bannerHeading = {contact.edges[0].node.bannerHeading}      
        bannerButtonOneLink = {contact.edges[0].node.bannerButtonOneLink}
        bannerButtonOneText = {contact.edges[0].node.bannerButtonOneText}
        bannerButtonTwoLink = {contact.edges[0].node.bannerButtonTwoLink}
        bannerButtonTwoText = {contact.edges[0].node.bannerButtonTwoText}
        breadcrumb="Home - "
        pageName="Contact Us"
        showGoogleReviews={true}
      ></GeneralPageSubHeader>

      <section className="full-width-section">
        <div className="generic-page-content">
          <SectionTitle
            sectionNumber=""
            sectionTitle="Contact"
            sectionTitleSpan="25 Spaces Qatar Real Estate Agents"
          ></SectionTitle>
          <div className="generic-page-text">
            <p>Welcome to 25 Spaces, your premier destination for all your real estate needs in Qatar. As trusted real estate agents in the region, we specialize in property sales and leasing management. Whether you're looking to buy, sell, or rent a property in Qatar or have any property-related queries, our team of experienced professionals is here to assist you.</p>


            <div className="contact-form-container">


              <form accept-charset="UTF-8" onSubmit={handleOnSubmit} id='Contact Form'>
                <input type="hidden" name="formname" value='Contact Form' />
                <input className="half-input" type="text" name="first-name" placeholder="First Name" required />
                <input className="half-input" type="text" name="last-name" placeholder="Last Name" required />
                <input type="email" name="email" placeholder="Email" required />
                <input type="tel" name="phone" placeholder="Phone Number" required />
                <input type="text" name="message" placeholder="Your Message" required />
                {successForm
                  ? <h4 style={sentButtonStyles}>Thank you!<br />Your Messages has been sent successfully.<br />We will get back to you soon.</h4>
                  : ""
                }
                <button className="clear-btn" style={sentButtonStyles} type="submit">Send</button>

              </form>


            </div>

            <div className="map-container">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.1387450739217!2d51.54965223846073!3d25.36891471429651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c4b871e3d2fd%3A0xf54afa7d6fa0189!2s25%20Spaces%20Real%20Estate!5e0!3m2!1sen!2sfr!4v1621013518062!5m2!1sen!2sfr" allowfullscreen="" loading="lazy"></iframe>
            </div>

          </div>
        </div>


        <GenericPageImageContact
          image={contact.edges[0].node.contactPageImage}
          imageAlt={contact.edges[0].node.contactPageImage.title}
          logo={logo}
        ></GenericPageImageContact>

      </section>
    </Layout>
  )
}

export default ContactUs