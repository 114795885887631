import React, { useState } from 'react';
import axios from 'axios'
import './book-a-valuation.scss';


const BookAValuation = () => {

    const [successForm, setSuccessForm] = useState(false)

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
            setSuccessForm(true)
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/777c5d3d-1388-4aa2-9224-8e0a464d44ac",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    const sentButtonStyles = {
        marginTop: "30px",
    }
    return (
        <div className="contact-form-container book-a-viewing">
            <div className="book-a-viewing-from sticky">
                <h4>BOOK A VALUATION</h4>
                <p>HOW MUCH IS YOUR PROPERTY WORTH?</p>
                <form accept-charset="UTF-8" onSubmit={handleOnSubmit} id="Book a Valuation">
                    <input type="hidden" name="formname" value="Book a Valuation" />
                    <input className="half-input" type="text" name="first-name" placeholder="First Name" required />
                    <input className="half-input" type="text" name="last-name" placeholder="Last Name" required />
                    <input className="half-input" type="email" name="email" placeholder="Email" required />
                    <input className="half-input" type="tel" name="phone" placeholder="Phone Number" required />
                    <input type="text" name="property_location" placeholder="Enter Property Location" required />
                    {successForm
                        ? <h4 style={sentButtonStyles}>Thank you!<br />Your Messages has been sent successfully.<br />We will get back to you soon.</h4>
                        : ""
                    }
                    <button className="clear-btn" style={sentButtonStyles} type="submit">SUBMIT</button>

                </form>



            </div>
        </div>
    )
}

export default BookAValuation