import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { Link } from "gatsby"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

import './our-services.scss';
import ServiceCard from '../ServiceCard/ServiceCard';


const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>
const Heading4 = ({ children }) => <h4>{children}</h4>
const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Text>{children}</Text>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <Heading3>{children}</Heading3>
      )
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return(
          <Heading4>{children}</Heading4>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
      )
    }
  },
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
}

const Services = () =>{
   
    return(
    
    <StaticQuery
    query={graphql`
        query {
            featured: allContentfulHomepageElements (
                filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}
            ) {
                edges {
                  node {
                        homepageServiceListingHeading
                        homepageServiceListingDescription {
                          raw
                         }
                         service {
                            serviceTitle
                            slug
                            serviceMainImage {
                              gatsbyImageData(
                                layout: FULL_WIDTH
                                width: 300
                                height: 240
                                cropFocus: CENTER
                                placeholder: BLURRED
                                aspectRatio: 1
                              )
                              title
                            }
                          }
                      }

                   }
            }
           
        }
    `}
    
    render={data => (
        <section className='our-services-section'>  
                    <h2 className='services-title'>{data.featured.edges[0].node.homepageServiceListingHeading}</h2>
                    <p className='services-desc'>{renderRichText(data.featured.edges[0].node.homepageServiceListingDescription ,options)}</p>
                    <div className="service-card-section">
                      {data.featured.edges[0].node.service.map((services , i) =>{
                      
                      return(
                      <ServiceCard 
                        key={i}
                        slug={services.slug}
                        serviceImage={services.serviceMainImage}
                        serviceTitle={services.serviceTitle}
                        alt={services.serviceMainImage.title}
                      />
                      )
                    })}
                    </div>
            
                    <Link to="/our-services/" className="btn">
                              Our Services
                    </Link>
        </section>
    )}
/>
    )
}
export default Services;
