import React from 'react'
import './all-news-posts.scss'
import PageSubtitle from '../PageSubtitle/page-subtitle'
import ClearButtonExternal from '../buttons/ClearButtonExternal/clear-button-external'

import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const AllNewsPosts = () => {
    return (
        <StaticQuery
            query = {graphql`
                query {
                    newsPosts: allContentfulNewsPost (filter: {title: {ne: "Sample News Post - DO NOT DELETE"},
                    isThisAnInternationalNewsPost: {eq: false}}
                    sort: {fields: createdAt, order: DESC}
                    ) {
                        edges {
                            node {
                                title
                                externalPage
                                newsDescription {
                                    newsDescription
                                }
                                newsImage {
                                    gatsbyImageData(
                                        aspectRatio: 1.5
                                        cropFocus: CENTER
                                        layout: CONSTRAINED
                                        width: 600
                                        placeholder: BLURRED
                                    )
                                    title
                                }
                            }
                        }
                    }
                }
            `}
            render = { data => (
                <section className="news-posts-section" id="news-section">
                    <PageSubtitle
                        subtitle = "News"
                    ></PageSubtitle>
                    {data.newsPosts.edges.map( (newsPost, i) => {
                        return (
                            <div key={i} className="news-block-container">
                                <div className="rightText-image-post-block">
                                    <div className="post-image-container">
                                        {/* <img src={newsPost.node.newsImage.file.url} alt={newsPost.node.newsImage.description}/> */}
                                        <GatsbyImage image={getImage(newsPost.node.newsImage)} alt={newsPost.node.newsImage.title} />
                                    </div>
                                    <div className="post-intro-text-container">
                                        <h3>{newsPost.node.title}</h3>
                                        <p>{newsPost.node.newsDescription.newsDescription}</p>
                                        <ClearButtonExternal
                                            buttonText = "Read More +"
                                            href = {newsPost.node.externalPage}
                                            target = "blank"
                                        ></ClearButtonExternal>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </section>
            )}
        />  
    )
}

export default AllNewsPosts