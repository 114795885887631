import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ReviewImg from "../../images/5-star-google-review-logo.svg";

const GoogleReview = (props) => {
    return (
        <StaticQuery
            query={graphql`
            query {
                siteSettings: allContentfulSiteGeneralSettings(
                    filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
                ) {
                    edges {
                    node {
                        googleReviewRating
                        googleReviewTotal
                    }
                    }
                }
            }
        `}
            render={data => (
                <div class="google-reviews-wrap">
                    <div class="review-logo">
                        <img src={ReviewImg} alt="review us on Google" width="74" height="30" />
                    </div>
                    <p><strong>{data.siteSettings.edges[0].node.googleReviewRating}/5 </strong>Rating from <strong>{data.siteSettings.edges[0].node.googleReviewTotal}</strong> customer reviews</p>
                </div>
            )}
        />
    )
}

export default GoogleReview
