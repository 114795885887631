import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { TwitterShareButton, InstapaperShareButton, FacebookShareButton } from 'react-share'
import Twitter from '../../images/twitter.svg';
import Insta from '../../images/insta.svg';
import Facebook from '../../images/fb.svg';
import Youtube from '../../images/youtube.svg';
import './BlogShare.scss';



const BlogShare = (props) => {

    const data = useStaticQuery(graphql`
            query {
                instagram: allContentfulUrlAddresses (filter: {linkName: {eq: "Instagram"}}) {
                    edges {
                    node {
                        linkName
                        linkUrlAddress
                    }
                    }
                }
                youtube: allContentfulUrlAddresses (filter: {linkName: {eq: "Youtube"}}) {
                    edges {
                    node {
                        linkName
                        linkUrlAddress
                    }
                    }
                }
                facebook: allContentfulUrlAddresses (filter: {linkName: {eq: "Facebook"}}) {
                    edges {
                    node {
                        linkName
                        linkUrlAddress
                    }
                    }
                }
                twitter: allContentfulUrlAddresses (filter: {linkName: {eq: "Twitter"}}) {
                    edges {
                    node {
                        linkName
                        linkUrlAddress
                    }
                    }
                }
            }
    `);

    const { instagram, twitter, youtube, facebook } = data;
    const [windowLocation, setWindowLocation] = useState()
    useEffect(() => {
        setWindowLocation(window.location.href);
    })
    return (
        <div className="blog-share">

            <h5>Leave your living space to us!</h5>
            <p>Stay up to date with new investment opportunities by following us on social media:</p>

            <div className='share'>
                {/* <TwitterShareButton url={windowLocation}>
                    <div className="media-icon">
                        <img src={Twitter} alt="Twitter" />
                    </div>
                </TwitterShareButton>
                <InstapaperShareButton url={windowLocation}>
                    <div className="media-icon">
                        <img src={Insta} alt="Twitter" />
                    </div>
                </InstapaperShareButton>
                <FacebookShareButton url={windowLocation}>
                    <div className="media-icon">
                        <img src={Facebook} alt="Facebook" />
                    </div>
                </FacebookShareButton> */}
                <div className="media-icon">
                    <a target="_blank" href={twitter.edges[0].node.linkUrlAddress}>
                        <img src={Twitter} alt="Twitter" />
                    </a>

                </div>
                <div className="media-icon">
                    <a target="_blank" href={instagram.edges[0].node.linkUrlAddress}>
                        <img src={Insta} alt="Insta" />
                    </a>
                </div>
                <div className="media-icon">
                    <a target="_blank" href={facebook.edges[0].node.linkUrlAddress}>
                        <img src={Facebook} alt="Facebook" />
                    </a>
                </div>
                <div className="media-icon">
                    <a target="_blank" href={youtube.edges[0].node.linkUrlAddress}>
                        <img src={Youtube} alt="Youtube" />
                    </a>

                </div>

            </div>



        </div>
    )
}

export default BlogShare