import React from 'react'
import './all-team-members.scss'

import PageSubtitle from '../PageSubtitle/page-subtitle'
import ClearButton from '../buttons/ClearButton/clear-button.js'

import { StaticQuery, graphql, Link } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const AllTeamMembers = () => {
    return (
        <StaticQuery
            query = {graphql`
                query {
                    teamMembers: allContentfulTeamMember(
                    sort: {fields: createdAt, order: DESC}) {
                        edges {
                            node {
                                id
                                slug
                                fullName
                                position
                                photo{
                                ...on ContentfulAsset {
                                    contentful_id
                                    __typename
                                    gatsbyImageData(
                                        aspectRatio: 0.9
                                        cropFocus: CENTER
                                        placeholder: BLURRED
                                        width: 300
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render = { data => (
                <section className="team-members-section">
                    {data.teamMembers.edges.map( (teamMember, i) => {
                        return (
                            <div key={i} className="team-members-container">
                                <Link to={`/our-team/${teamMember.node.slug}`} className='team-member-link'>
                                    <div className='team-photo'>
                                    <GatsbyImage image={getImage(teamMember.node.photo)} alt={teamMember.node.photo.title} />
                                    </div>
                                    <h4>{teamMember.node.fullName}</h4>
                                    <p className='small'>{teamMember.node.position}</p>
                                </Link>
                            </div>
                        )
                    })}
                </section>
            )}
        />
    )
}

export default AllTeamMembers