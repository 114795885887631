import React from 'react'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
import SectionTitle from '../components/SectionTitle/section-title'

import { graphql } from 'gatsby'
import ImageContainer from '../components/ImageContainer/image-container'
import PageIntro from '../components/PageIntro/page-intro'
import BasicSection from '../components/BasicSection/basic-section'
import TwoColumnsInnerSection from '../components/TwoColumnsInnerSection/two-columns-inner-section'
import TestimonialBlock from '../components/TestimonialBlock/testimonial-block'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

export const query = graphql`
    {
        testimonialsIntro: allContentfulGeneralTitleIntroSection(
            filter: {slug: {eq: "what-our-clients-are-saying"}}
          ) {
            edges {
              node {
                id
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                  raw
                }
                pageHeaderImage {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
                bannerButtonOneLink {
                    linkUrlAddress
                }
                bannerButtonOneText
                bannerButtonTwoLink {
                    linkUrlAddress
                }
                bannerButtonTwoText
                bannerHeading
                bannerText
                pageImage {
                  ...on ContentfulAsset {
                      contentful_id
                      __typename
                      gatsbyImageData(
                      aspectRatio: 1.5
                      cropFocus: CENTER
                      placeholder: BLURRED
                      width: 1000
                    )
                    title
                  }
                }
              }
            }
          },
        testimonials: allContentfulTestimonial (
            filter: {clientsName: {ne: "Sample Client's Testimonial - DO NOT DELETE"}}
          ) {
            edges {
              node {
                id
                clientsName
                clientsTestimonial {
                  clientsTestimonial
                }
                clientsTitleAndCompany
                rating
              }
            }
          }
    }
`

const WhatOurClientsAreSaying = ({ data: {testimonialsIntro, testimonials}}) => {
    return (
    
        <Layout>

            <SeoHelmet
              metaTitle = "Raves for the Top Real Estate Agency | 25 Spaces Real Estate"
              metaDescription = "What makes 25 Spaces Real Estate the best real estate company in Qatar? Find out as you read their clients' testimonials here. Contact them for more info!"
              ogimage = {testimonialsIntro?.edges[0]?.node?.pageHeaderImage}
            ></SeoHelmet>
            
            <GeneralPageSubHeader
                image = {testimonialsIntro.edges[0].node.pageHeaderImage}
                imageAlt = {testimonialsIntro.edges[0].node.pageHeaderImage.title}
                breadcrumb = "Home - About Us - "
                pageName = "What Our Clients Are Saying"
                bannerHeading = {testimonialsIntro.edges[0].node.bannerHeading}
                bannerText = {testimonialsIntro.edges[0].node.bannerText}
                bannerButtonOneLink = {testimonialsIntro.edges[0].node.bannerButtonOneLink}
                bannerButtonOneText = {testimonialsIntro.edges[0].node.bannerButtonOneText}
                bannerButtonTwoLink = {testimonialsIntro.edges[0].node.bannerButtonTwoLink}
                bannerButtonTwoText = {testimonialsIntro.edges[0].node.bannerButtonTwoText}
            ></GeneralPageSubHeader>

            <section className="full-width-section">
                <div className="generic-page-content">
                    <SectionTitle
                        sectionNumber =""
                        sectionTitle="What Our Clients"
                        sectionTitleSpan="Are Saying"
                    ></SectionTitle>
                    <PageIntro
                        introText = {testimonialsIntro.edges[0].node.introText}
                    ></PageIntro>
                </div>
                <ImageContainer
                    image = {testimonialsIntro.edges[0].node.pageImage}
                    imageAlt = {testimonialsIntro.edges[0].node.pageImage.title}
                ></ImageContainer>
            </section>

            <BasicSection>
                <TwoColumnsInnerSection>
                    {testimonials.edges.map( (testimonial, i) => {
                        return (
                            <TestimonialBlock
                                key = {i}
                                rating = {testimonial.node.rating}
                                clientName = {testimonial.node.clientsName}
                                clientTestimonial = {testimonial.node.clientsTestimonial.clientsTestimonial}
                                clientTitle = {testimonial.node.clientsTitleAndCompany}
                            ></TestimonialBlock>
                        )
                    })}
                </TwoColumnsInnerSection>
            </BasicSection>
        </Layout>
    )
}

export default WhatOurClientsAreSaying