import React from 'react'
import './testimonials-small.scss';

import SectionTitle from '../SectionTitle/section-title'
import WhiteToRedButton from '../buttons/WhiteToRedButton/white-to-red-button';

import { StaticImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { StaticQuery, graphql } from 'gatsby'

const displayStars = (stars) => {
    let starCount = []

    for (let i = stars; i<=5 && i>0; i--) {
        starCount.push (
            <FontAwesomeIcon className="font-awesome" icon={faStar}/>
        )
    }

    return starCount
}


const TestimonialsSmall = () => {
    return (
        <StaticQuery
            query = { graphql`
                query {
                    testimonials: allContentfulTestimonial(
                        filter: {clientsName: {ne: "Sample Client's Testimonial - DO NOT DELETE"}}
                        limit: 3
                        sort: {fields: createdAt, order: DESC}) {
                            edges {
                            node {
                                id
                                clientsName
                                clientsTestimonial {
                                clientsTestimonial
                                }
                                clientsTitleAndCompany
                                rating
                            }
                        }
                    }
                }
            `}
            render = {data => (
                <section className="homepage-testimonials-section">

                    <div className="homepage-testimonials-header">
                        <div className="testimonials-image">
                        <StaticImage
                            src="../../images/temp/testimonials2.jpg"
                            alt="What our clients are saying"
                            placeholder="blurred"
                            />
                        </div>
                        <SectionTitle
                            sectionNumber = "03"
                            sectionTitle = "What Our Clients"
                            sectionTitleSpan = "Are Saying"
                            isGoogleReview
                        ></SectionTitle>
                    </div>

                    <div className="testimonials-container">
                        
                        <div className="testimonials-blocks">
                            {data.testimonials.edges.map(( testimonial, i) => {
                                return (
                                    <div key = {i} className="testimonial-block">
                                        <div className="rating-container">
                                            {displayStars(testimonial.node.rating)}
                                        </div>
                                        <p>{testimonial.node.clientsTestimonial.clientsTestimonial}</p>
                                        <p><strong>{testimonial.node.clientsName}</strong></p>
                                        <p><em>{testimonial.node.clientsTitleAndCompany}</em></p>
                                    </div>
                                )
                            })}
                        
                        </div>

                        <WhiteToRedButton
                            href = "/what-our-clients-are-saying"
                            buttonText="Read More +">    
                        </WhiteToRedButton>

                    </div>

                </section>
            )}
        />
    )
}


export default TestimonialsSmall