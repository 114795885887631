import React from 'react'
import './generic-page-image-contact.scss'

import GenericPageContactBox from '../GenericPageContactBox/generic-page-contact-box'
// import StaticThumbnail from '../StaticThumbnail/static-thumbnail'

import ImageContainer from '../ImageContainer/image-container'

const GenericPageImageContact = (props) => {
    return (
        <div className="generic-page-image-contact">
                    <div className="page-image-container">
                        <ImageContainer
                            image = {props.image}
                            imageAlt = {props.imageAlt}
                        ></ImageContainer>
                    </div>
                    <GenericPageContactBox phone={props.phone}></GenericPageContactBox>
                </div>
    )
}

export default GenericPageImageContact