
import React from 'react' 
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import SectionTitle from '../components/SectionTitle/section-title'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'
import logo from '../images/logo-color@2x.png'

import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import { graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { BLOCKS, MARKS, INLINES} from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import DarkButton from '../components/buttons/DarkButton/dark-button'

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>
const Heading4 = ({ children }) => <h4>{children}</h4>
// const HyperLink = ({ children }) => <a href="#"><strong>{children}</strong></a>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>

    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text>{children}</Text>
            )
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return(
                <Heading3>{children}</Heading3>
            )
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return(
                <Heading4>{children}</Heading4>
            )
        },
        [INLINES.HYPERLINK]: (node, children) => {
            return (
                // <HyperLink>{children}</HyperLink>
                <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
            )
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
   

            return (
                // <img src={node.data.target.fixed.src} alt={node.data.target.title}/>
                <GatsbyImage image={getImage(node.data.target)} alt={node.data.target.title} />
            )
        }
    }
}

export const query = graphql`
    query($slug: String!) {
        page: contentfulGeneralPage(slug: {eq: $slug}) {
            seo {
                metaTitle
                metaDescription {
                    metaDescription
                }
            }
            pageHeaderImage {
                gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                  title
            }
            pageTitle
            bannerButtonOneLink {
            linkUrlAddress
            }
            bannerButtonOneText
            bannerButtonTwoLink {
            linkUrlAddress
            }
            bannerButtonTwoText
            bannerHeading
            bannerText
            displayGoogleReviews
            titleFirstBreak
            titleSecondBreak
            pageContent {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            layout: CONSTRAINED
                            width: 1000
                            placeholder: BLURRED
                          )
                          title
                    }
                }
            }
            pageSlogan
            pageImage {
                gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    layout: CONSTRAINED
                    width: 1200
                    placeholder: BLURRED
                  )
                  title
            }
            endOfPageButtonLink {
                ... on ContentfulUrlAddresses {
                    linkUrlAddress
                }
            }
            hasEndOfPageButton
            endOfPageButtonTitle
        }
    }
`






const GeneralPage = ({ data: {page}}) => {
    const { pageContent } = page
    //console.log("generalpage",page)

    const checkEndOfPageButton = () => {
        if(page.endOfPageButtonTitle) {
            return (
                <DarkButton
                    href = {page.endOfPageButtonLink.linkUrlAddress}
                    buttonText = {page.endOfPageButtonTitle}
                ></DarkButton>
            )
        }
    }
    
    return (
        <Layout>

            <SeoHelmet
                metaTitle = {page.seo.metaTitle}
                metaDescription = {page.seo.metaDescription.metaDescription}
                ogimage = {page?.pageHeaderImage}
            ></SeoHelmet>


            <GeneralPageSubHeader
                image = {page.pageHeaderImage}
                imageAlt = {page.pageHeaderImage.title}
                breadcrumb = "Home - "
                pageName = {page.pageTitle}
                bannerHeading = {page.bannerHeading}
                bannerText = {page.bannerText}
                bannerButtonOneLink = {page.bannerButtonOneLink}
                bannerButtonOneText = {page.bannerButtonOneText}
                bannerButtonTwoLink = {page.bannerButtonTwoLink}
                bannerButtonTwoText = {page.bannerButtonTwoText}                
                showGoogleReviews ={page.displayGoogleReviews ? true : false}
            ></GeneralPageSubHeader>

            <section className = "full-width-section">
                <div className = "generic-page-content">
                    <SectionTitle
                        sectionNumber = ""
                        sectionTitle = {page.titleFirstBreak}
                        sectionTitleSpan = {page.titleSecondBreak}
                    ></SectionTitle>
                    <div className="generic-page-text">
                        {pageContent && renderRichText(pageContent, options)}
                    </div>
                    <h2 className="small-h2 red-heading">Leave your Living Space To Us!</h2>
                    <div className='page-end-cta-section'>
                        <div>
                            {page.hasEndOfPageButton
                                ? <DarkButton
                                    href = {page.endOfPageButtonLink.linkUrlAddress}
                                    buttonText = {page.endOfPageButtonTitle}
                                ></DarkButton>
                            : ''}
                        </div>
                        <div>
                            {
                                page.pageTitle === "Buy The Perfect Property For You In Doha" ? 
                                <DarkButton
                                    href = {"/doha-neighborhoods-guide/"}
                                    buttonText = {"Doha Neighborhoods"}
                                ></DarkButton>
                            : ''
                            }
                        </div>
                    </div>
                    
                </div>
                <GenericPageImageContact
                    image = {page.pageImage}
                    imageAlt = {page.pageImage.title}
                    logo = {logo}
                ></GenericPageImageContact>
            </section>
        </Layout>
    )
}

export default GeneralPage