import React from 'react'
import BasicSection from '../components/BasicSection/basic-section'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import Layout from '../components/Layout/layout'
import ListingThumbnailNewDevelopments from '../components/ListingThumbnail/listing-thumbnail-newdevelopments'
import SectionTitle from '../components/SectionTitle/section-title'
import TwoColumnsInnerSection from '../components/TwoColumnsInnerSection/two-columns-inner-section'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'
import logo from '../images/logo-color@2x.png'
import { graphql } from 'gatsby'
// import IntroSideNav from '../components/IntroSideNav/intro-side-nav'
import TestimonialsSmall from '../components/TestiomonialsSmall/testimonials-small'
import PageSubsection from '../components/PageSubsection/page-sub-section'
import ImageContainer from '../components/ImageContainer/image-container.js'
import PageIntro from '../components/PageIntro/page-intro'
import AllInternationalNews from '../components/AllNewsPosts/all-international-news'
import PropertyCard from '../components/PropertyCard/PropertyCard';
export const query = graphql`
    {
        properties: allContentfulProperty(filter: {isThisAnInternationalProperty: {eq: true}} sort: {fields: createdAt, order: DESC}) {
            edges {
              node {
                createdAt
                propertyImages {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    width: 600
                    height: 500
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1
                    )
                    title
                }
                images{
                  url
                }
                provider
                propertyPrice
                propertyType
                propertyInternationalPrice
                internationalCurrency
                neighborhood {
                  title
                  slug
                }
                slug
                propertyOneLiner
                cityAndCountry
              }
            }
        }
        international: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "International Properties"}}) {
            edges {
              node {
                id
                bannerHeading
                bannerText
                bannerButtonOneLink {
                linkUrlAddress
                }
                bannerButtonOneText
                bannerButtonTwoLink {
                linkUrlAddress
                }
                bannerButtonTwoText
                title
                titleFirstBreak
                titleSecondBreak
                introText {
                  raw
                }
                pageHeaderImage {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
                pageImage {
                  ...on ContentfulAsset {
                      contentful_id
                      __typename
                      gatsbyImageData(
                      aspectRatio: 1.5
                      cropFocus: CENTER
                      placeholder: BLURRED
                      width: 1000
                    )
                    title
                  }
                }
              }
            }
          }
          about: allContentfulPageSubsection(filter: {sectionTitle: {eq: "About Us"}}) {
            edges {
            node {
                id
                sectionImage {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 600
                    cropFocus: CENTER
                    placeholder: BLURRED
                    aspectRatio: 1.5
                    )
                    title
                }
                sectionTitle
                sectionText {
                raw
                }
                slug
            }
            }
        }
        strategy: allContentfulPageSubsection(filter: {sectionTitle: {eq: "Our Strategy"}}) {
          edges {
          node {
              id
              sectionImage {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 600
                  cropFocus: CENTER
                  placeholder: BLURRED
                  aspectRatio: 1.5
                  )
                  title
              }
              sectionTitle
              sectionText {
              raw
              }
              slug
          }
          }
      }
    }
`


const InternationalProperties = ({ data: { properties, international, about, strategy } }) => {
 
  return (
    <Layout>

      <SeoHelmet
        metaTitle={`Luxury ${international.edges[0].node.title} for Sale | 25 Spaces real estate`}
        metaDescription="Discover luxury international real estate and properties for sale worldwide. Find your dream home abroad with our expert assistance in international real estate. Contact us today!"
        ogimage = {international?.edges[0]?.node.pageHeaderImage}
      ></SeoHelmet>
      <GeneralPageSubHeader
        image={international.edges[0].node.pageHeaderImage}
        imageAlt={international.edges[0].node.pageHeaderImage.title}
        breadcrumb="Home - Properties - "
        pageName={international.edges[0].node.title}
        bannerHeading={international.edges[0].node.bannerHeading}
        bannerText={international.edges[0].node.bannerText}
        bannerButtonOneLink={international.edges[0].node.bannerButtonOneLink}
        bannerButtonOneText={international.edges[0].node.bannerButtonOneText}
        bannerButtonTwoLink={international.edges[0].node.bannerButtonTwoLink}
        bannerButtonTwoText={international.edges[0].node.bannerButtonTwoText}
      ></GeneralPageSubHeader>
      <BasicSection>
        <SectionTitle
          sectionNumber=""
          sectionTitle={international.edges[0].node.titleFirstBreak}
          sectionTitleSpan={international.edges[0].node.titleSecondBreak}
        ></SectionTitle>
      </BasicSection>
      
      {properties.edges.length > 0
        ? <section className='basic-section property-section'>

          {properties.edges.map((property, i) => {
            return (
              <PropertyCard
                key={i}
                propertydata={property.node}
                price={property.node.propertyPrice}
                internationalPrice={property.node.propertyInternationalPrice}
                internationalCurrency={property.node.internationalCurrency}
                neighborhood={property.node.neighborhood.title}
                slug={`${property.node.slug}`} 
                category={property.node.propertyCategory}
                oneLiner={property.node.propertyOneLiner}
                type={property.node.propertyType}
                paymentFrequency={property.node.paymentFrequency}
                isCommercial={false}
                provider={property.node.provider}
              />

            )
          })}
        </section>
        : ""
      }
      <section className="full-width-section  top-section">
        <div className="generic-page-content">
          <PageIntro
            introText={international.edges[0].node.introText}
          ></PageIntro>
        </div> 
        <GenericPageImageContact
          image={international.edges[0].node.pageImage}
          logo={logo}
        ></GenericPageImageContact>
      </section>
      <AllInternationalNews></AllInternationalNews>

      <PageSubsection
        subtitle={about.edges[0].node.sectionTitle}
        sectionText={about.edges[0].node.sectionText}
        image={about.edges[0].node.sectionImage}
        imageAlt={about.edges[0].node.sectionImage.title}
      ></PageSubsection>

      <PageSubsection
        subtitle={strategy.edges[0].node.sectionTitle}
        sectionText={strategy.edges[0].node.sectionText}
        image={strategy.edges[0].node.sectionImage}
        imageAlt={strategy.edges[0].node.sectionImage.title}
      ></PageSubsection>

      <TestimonialsSmall></TestimonialsSmall>
    </Layout>
  )
}

export default InternationalProperties