import React from 'react'
import './dark-button.scss'

import { Link } from 'gatsby'

const DarkButton = (props) => {
    return (
        <Link to={props.href} className="primary-btn">
            {props.buttonText}
        </Link>
    )
}

export default DarkButton