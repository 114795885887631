import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import './section-title.scss'; 
import { StaticQuery, graphql } from 'gatsby'
const SectionTitle = (props) => {

    return (
        <StaticQuery
            query={graphql`
            query {
                siteSettings: allContentfulSiteGeneralSettings(
                    filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
                ) {
                    edges {
                    node {
                        googleReviewRating
                        googleReviewTotal
                    }
                    }
                }
                
              }
            `}
            render={data => (
                <div className="section-header-container">
                    <p className={`section-number ${!props.sectionNumber ? 'no-number' : ""}`}>{props.sectionNumber}</p>
                    <h2 className="hidden-heading">{[props.sectionTitle, props.sectionTitleSpan].join(" ")}</h2>
                    <h2 className={props.theme}>{props.sectionTitle}</h2>
                    <h2><span>{props.sectionTitleSpan}</span></h2>
                    {props.isGoogleReview && <div className='home-reviews-section'>
                        <a href='https://www.google.com/search?q=25+spaces+real+estate&rlz=1C5CHFA_enFR1015FR1015&oq=25+spaces+&aqs=chrome.0.0i355i512j46i175i199i512j69i57j0i512j0i10i22i30j69i60l2j69i61.8433j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3e45c4b871e3d2fd:0xf54afa7d6fa0189,1,,,'>
                          
                            <StaticImage
                                src={"../../images/google-review-logo.svg"}
                                alt="Google Review Image"
                                placeholder="blurred"
                            />
                        </a>
                        <p>{data.siteSettings.edges[0].node.googleReviewRating}/5 Rating from {data.siteSettings.edges[0].node.googleReviewTotal} customer Reviews</p>
                    </div>}
                </div>
            )}
        />
    )
}

export default SectionTitle