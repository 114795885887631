import React from 'react'
import './white-to-red-button.scss';

import { Link } from 'gatsby'

const WhiteToRedButton = (props) => {
    return (
        <div className="button-container">
            <Link to={`${props.href}`} target={`${props.target ? "_blank" : ""}`} className="white-to-red-btn">
                {props.buttonText}
            </Link>
        </div>
    )
}

export default WhiteToRedButton